import React from 'react';
import '../styles/OurTeam.scss';
import Seo from '../components/Seo';
import DrShell from '../img/home/DrShell.png';
import Toto from '../img/home/0xToto.png';
import available from '../img/home/available.png';
import { User } from 'lucide-react';

const OurTeam = () => {
  const teamMembers = [
    { name: '@DrShell', role: 'Fondateur & CEO', image: DrShell },
    { name: '@0xToto', role: 'Community Manager', image: Toto },
    { name: 'A pourvoir', role: 'Responsable Cybersécurité', image: available },
    { name: 'A pourvoir', role: 'Expert en OSINT', image: available },
  ];

  const openUrl = (url) => {
    window.open(url, '_blank', 'noreferrer');
  }

  return (
    <>
      <Seo
        title="L'Équipe d'OSINT | Ozzzint.fr"
        description="Rencontrez notre équipe d'experts en OSINT et cybersécurité. Découvrez nos opportunités de carrière et rejoignez l'aventure Ozzzint."
        canonicalUrl="https://ozzzint.fr/our-team"
        keywords="équipe OSINT, recrutement cybersécurité, experts investigation numérique, carrière OSINT"
        author="Ozzzint"
      />
      <div className="our-team">
        <div className="container">
          <h1 className="title"><User size={48} /> L'Équipe</h1>

          <section className="team-intro">
            <p className="lead-text">
              Chez Ozzzint.fr, nous travaillons ensemble pour créer des outils innovants et des solutions pour protéger les individus et les entreprises face aux menaces numériques.
            </p>
          </section>

          <section className="team-grid">
            {teamMembers.map((member, index) => (
              <div key={index} className="team-member">
                <img src={member.image} alt={member.name} className="member-image" />
                <h3 className="member-name">{member.name}</h3>
                <p className="member-role">{member.role}</p>
              </div>
            ))}
          </section>

          <section className="team-values">
            <h2 className="section-title">Nos Valeurs</h2>
            <ul className="values-list">
              <li>Intégrité et transparence dans toutes nos actions</li>
              <li>Innovation constante pour rester à la pointe de la technologie</li>
              <li>Respect de la vie privée et des données personnelles</li>
              <li>Collaboration et partage de connaissances</li>
            </ul>
          </section>

          <section className="join-team">
            <h2 className="section-title">Rejoignez-nous</h2>
            <p className="section-text">
              Vous êtes passionné par la cybersécurité et l'OSINT ? Nous sommes toujours à la recherche de talents pour renforcer notre équipe.
            </p>
            <button className="cta-button" onClick={() => openUrl("https://discord.com/invite/QqjYV8HpYE")}>Nous rejoindre !</button>
          </section>
        </div>
      </div>
    </>
  );
};

export default OurTeam;