import { useState } from "react";
import { Search, Loader } from "lucide-react";
import '../styles/SearchForm.scss';

function SearchForm({ handleSearch, renderInput, firstSearch, isSearching, termsAccepted, setTermsAccepted, searchType, setSearchType }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const searchOptions = [
        // { value: "phone", label: "Numéro de téléphone" },
        // { value: "email", label: "Adresse e-mail" },
        // { value: "username", label: "Nom d'utilisateur" },
        { value: "domain", label: "Nom de domaine" },
        { value: "ipaddress", label: "Adresse IP" },
        
    ];

    const handleOptionClick = (value) => {
        setSearchType(value);
        setDropdownOpen(false);
    };

    return (
        <form onSubmit={handleSearch} className="search-form">
            <div className="dropdown-wrapper">
                <div
                    className={`dropdown-header ${dropdownOpen ? "open" : ""}`}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                    {searchType ? searchOptions.find(option => option.value === searchType).label : "Sélectionnez une option"}
                    <span className="dropdown-arrow">{dropdownOpen ? "▲" : "▼"}</span>
                </div>
                {dropdownOpen && (
                    <ul className="dropdown-options">
                        {searchOptions.map((option) => (
                            <li
                                key={option.value}
                                onClick={() => handleOptionClick(option.value)}
                                className={searchType === option.value ? "selected" : ""}
                            >
                                {option.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            {renderInput()}
            {firstSearch && (
                <div className="terms-checkbox">
                    <input
                        type="checkbox"
                        id="terms"
                        checked={termsAccepted}
                        onChange={(e) => setTermsAccepted(e.target.checked)}
                    />
                    <label htmlFor="terms">J'accepte les conditions d'utilisation</label>
                </div>
            )}
            <button type="submit" className="search-button" disabled={isSearching}>
                {isSearching ? <Loader size={20} /> : <Search size={20} />}
                {isSearching ? 'Recherche en cours...' : 'Rechercher'}
            </button>
        </form>
    );
}

export default SearchForm;
