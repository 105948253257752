import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { UserProvider } from './contexts/UserContext';
import { NotificationProvider } from './contexts/NotificationContext';
import NotificationContainer from './components/Notifications/NotificationContainer';
import { BrowserRouter as Router } from 'react-router-dom'; // Import Router

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <UserProvider>
        <NotificationProvider>
          <App />
          <NotificationContainer />
        </NotificationProvider>
      </UserProvider>
    </Router>
  </React.StrictMode>
);