import React, { useState } from 'react';
import '../styles/ApiPricingDocs.scss';
import Seo from '../components/Seo';
import { Check, X, ChevronDown, ChevronUp, Zap, EuroIcon, FileText } from 'lucide-react';

const ApiPricingDocs = () => {
    const [activeTab, setActiveTab] = useState('pricing');
    const [expandedFaq, setExpandedFaq] = useState(null);

    const pricingPlans = [
        {
            name: 'Starter',
            price: '€249',
            period: '/mois',
            features: [
                '1,000 requêtes par jour',
                '20,000 requêtes par mois',
                'Accès aux données de base',
                'Support par email',
                'Mise à jour mensuelle'
            ],
            cta: 'Bientôt disponible',
            highlighted: false
        },
        {
            name: 'Pro',
            price: '€1999',
            period: '/mois',
            features: [
                '20,000 requêtes par jour',
                '420,000 requêtes par mois',
                'Accès aux données avancées',
                'Cache plus court',
                'Support prioritaire',
                'Mise à jour hebdomadaire',
                'API personnalisée'
            ],
            cta: 'Bientôt disponible',
            highlighted: true
        },
        {
            name: 'Enterprise',
            price: 'Sur mesure',
            period: '',
            features: [
                'Requêtes illimitées',
                'Accès complet aux données',
                'Bypass du cache',
                'Support dédié 24/7',
                'Mises à jour en temps réel',
                'Intégration sur mesure',
                'Formation et consulting'
            ],
            cta: 'Bientôt disponible',
            highlighted: false
        }
    ];

    const faqItems = [
        {
            question: "Comment puis-je commencer à utiliser l'API Ozzzint ?",
            answer: "Pour commencer, inscrivez-vous sur notre portail développeur, choisissez un plan tarifaire, et vous recevrez immédiatement vos clés d'API. Vous pourrez alors commencer à faire des requêtes à notre API en suivant notre documentation."
        },
        {
            question: "Quels types de données puis-je obtenir via l'API Ozzzint ?",
            answer: "Notre API fournit des données OSINT provenant de diverses sources ouvertes, incluant mais sans s'y limiter : les réseaux sociaux, les registres publics, les bases de données en ligne, et les sites web publics. Les types de données spécifiques dépendent du plan tarifaire choisi."
        },
        {
            question: "L'API Ozzzint est-elle conforme au RGPD ?",
            answer: "Oui, nous prenons très au sérieux la conformité au RGPD. Toutes nos opérations de collecte et de traitement de données sont conformes aux réglementations européennes sur la protection des données. Nous fournissons également des outils pour aider nos clients à respecter leurs propres obligations RGPD."
        },
        {
            question: "Quelle est la disponibilité et la fiabilité de l'API ?",
            answer: "Nous visons un temps de fonctionnement de 99,9% pour notre API. Nous avons mis en place des systèmes redondants et des processus de surveillance continue pour assurer la plus haute disponibilité possible. En cas de problème, notre équipe de support est prête à intervenir 24/7."
        }
    ];

    const toggleFaq = (index) => {
        setExpandedFaq(expandedFaq === index ? null : index);
    };

    return (
        <>
            <Seo
                title="API OSINT : Tarifs et Documentation | Ozzzint.fr"
                description="Découvrez notre API OSINT puissante pour vos investigations numériques. Documentation complète et tarifs transparents pour les professionnels de la cybersécurité."
                canonicalUrl="https://ozzzint.fr/api-pricing-docs"
                keywords="API OSINT, documentation API sécurité, prix API investigation, cybersécurité API"
                author='Ozzzint'
            />
            <div className="api-pricing-docs">
                <div className="container">
                    <h1 className="title"><Zap size={48} /> End-Points API</h1>

                    <div className="tabs">
                        <button
                            className={`tab ${activeTab === 'pricing' ? 'active' : ''}`}
                            onClick={() => setActiveTab('pricing')}
                        >
                            <EuroIcon size={20} />
                            Tarification
                        </button>
                        <button
                            className={`tab ${activeTab === 'docs' ? 'active' : ''}`}
                            onClick={() => setActiveTab('docs')}
                        >
                            <FileText size={20} />
                            Documentation
                        </button>
                    </div>

                    {activeTab === 'pricing' && (
                        <section className="pricing-section">
                            <div className="pricing-grid">
                                {pricingPlans.map((plan, index) => (
                                    <div key={index} className={`pricing-card ${plan.highlighted ? 'highlighted' : ''}`}>
                                        <h2 className="plan-name">{plan.name}</h2>
                                        <div className="plan-price">
                                            <span className="price">{plan.price}</span>
                                            <span className="period">{plan.period}</span>
                                        </div>
                                        <ul className="features">
                                            {plan.features.map((feature, fIndex) => (
                                                <li key={fIndex}>
                                                    <Check size={20} />
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                        <button className="cta-button">{plan.cta}</button>
                                    </div>
                                ))}
                            </div>
                        </section>
                    )}

                    {activeTab === 'docs' && (
                        <section className="docs-section">
                            <h2><FileText size={24} /> Documentation de l'API</h2>
                            <p>Bienvenue dans la documentation de l'API Ozzzint.
                                <br />
                                Afin de pouvoir utiliser nos end-points, vous devez d'abord vous <a onClick={() => setActiveTab('pricing')}>inscrire</a>.
                                Vous pourrez ensuite choisir le plan qui vous convient, et recevoir vos clés d'API.

                                <br />
                                Suivez ces étapes pour commencer :</p>

                            <h3>1. L'authentification</h3>
                            <p>Toutes les requêtes à l'API doivent inclure votre clé API dans l'en-tête HTTP :</p>
                            <span className='info-text'>(Attention : Assurez-vous de remplacer "VOTRE_CLÉ_API" par la clé API réelle)</span>
                            <pre><code>
                                Authorization: Bearer VOTRE_CLÉ_API
                            </code></pre>

                            <h3>2. Faire une requête</h3>
                            <p>Endpoint de base : <code>https://api.ozzzint.com/v1/</code></p>
                            <pre><code>
                                <span className='info-text'>(Scan d'un IP)</span>
                                GET https://api.ozzzint.com/v1/ip?ip=192.168.1.1
                            </code></pre>
                            <pre><code>
                                <span className='info-text'>(Scan d'un domaine)</span>
                                GET https://api.ozzzint.com/v1/domain?domain=example.com
                            </code></pre>
                            <pre><code>
                                <span className='info-text'>(Scan d'un numéro de téléphone)</span>
                                GET https://api.ozzzint.com/v1/phone?phone=0600000000&country=FR
                            </code></pre>

                            <h3>3. Réponse de l'API</h3>
                            <p>Les réponses sont au format JSON. Exemple :</p>
                            <pre><code>
                                <span className='info-text'>(Réponse d'un scan d'IP)</span>
                                {`
{
  "status": "success",
  "data": {
    "ip": "192.168.1.1",
    "type": "residential",
    "location": "Paris, France",
    "longitude": "2.3522",
    "latitude": "48.8566",
    "asn": "AS010101",
    "isp": "Ozzzint Telecom",
    "organization": "Ozzzint Telecom",
    "postal_code": "75000",
    "timezone": "Europe/Paris",
    "asn_organization": "Ozzzint Telecom",
    "asn_domain": "ozzzint.com",
    "asn_route": "192.168.1.0/24",
    "asn_country_code": "FR"
  }
}
              `}
                            </code></pre>

                            <pre><code>
                                <span className='info-text'>(Réponse d'un scan de domaine)</span>
                                {`
{
  "status": "success",
  "data": {
    "domain": "example.com",
    "registrant_name": "John Doe",
    "registrant_email": "john.doe@example.com",
    "registrant_phone": "+33600000000",
    "registrant_address": "123 Rue de la Paix, Paris, France",
    "registrant_country": "FR",
    "registrant_city": "Paris",
    "registrant_state": "Île-de-France",
    "registrant_zip": "75000",
    "registrant_organization": "Ozzzint Telecom",
    "registrant_organization_type": "Corporation",
    "registrant_organization_country": "FR",
    "organization": "Ozzzint Telecom",
    "subdomains": ["www.example.com", "mail.example.com", "ftp.example.com"],
    "missingSecurityHeaders": ["X-Powered-By", "Server"],
    "ssl_certificates": [
        {
            "isValid": true,
            "ExpirationDate": "2024-01-01",
            "Issuer": "DigiCert Inc",
            "Subject": "CN=example.com,OU=Domain Control Validated,O=Let's Encrypt,C=US",
            "SignatureAlgorithm": "SHA256withRSA",
            "KeyAlgorithm": "RSA",
            "KeySize": 2048
        }
    ],
    "cms_detection": {
        "is_detected": true,
        "cms_name": "WordPress",
        "cms_version": "6.0.2",
        "cms_path": "/wp-admin/",
        "cms_plugins": ["Akismet", "WordPress SEO", "Contact Form 7"]
    },
    "waf_detection": {
        "is_detected": true,
        "waf_name": "Cloudflare",
        "waf_version": "1.0.0",
        "waf_mode": "block"
    }
}
              `}
                            </code></pre>

                            <pre><code>
                                <span className='info-text'>(Réponse d'un scan de numéro de téléphone)</span>
                                {`
{
  "status": "success",
  "data": {
    "phone": "+33600000000",
    "country": "FR",
    "carrier": "Orange",
    "line_type": "mobile",
    "status": "active",
    "location": "Paris, France",
    "latitude": "48.8566",
    "longitude": "2.3522",
    "timezone": "Europe/Paris",
    "asn": "AS010101",
    "isp": "Ozzzint Telecom",
    "organization": "Ozzzint Telecom",
    "postal_code": "75000",
    "asn_organization": "Ozzzint Telecom",
    "asn_domain": "ozzzint.com",
    "asn_route": "192.168.1.0/24",
    "asn_country_code": "FR"
  }
}
              `}
                            </code></pre>


                            <h3>4. Gestion des erreurs</h3>
                            <p>En cas d'erreur, l'API retournera un code d'état HTTP approprié et un message d'erreur :</p>
                            <pre><code>
                                {`
{
  "status": "error",
  "message": "Invalid API key",
  "code": "AUTH_001"
}
              `}
                            </code></pre>

                            <h3>5. Limites de taux</h3>
                            <p>Les limites de taux dépendent de votre plan. Consultez les en-têtes de réponse pour suivre votre utilisation :</p>
                            <pre><code>
                                X-RateLimit-Limit: 1000
                                X-RateLimit-Remaining: 999
                                X-RateLimit-Reset: 1623456789
                            </code></pre>

                            <h2>FAQ</h2>
                            <div className="faq-section">
                                {faqItems.map((item, index) => (
                                    <div key={index} className="faq-item">
                                        <div className="faq-question" onClick={() => toggleFaq(index)}>
                                            {item.question}
                                            {expandedFaq === index ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                                        </div>
                                        {expandedFaq === index && (
                                            <div className="faq-answer">{item.answer}</div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
};

export default ApiPricingDocs;