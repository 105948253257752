import React, { useState, useEffect } from 'react';
import { User, Shield, FlaskRound, Coins, TrendingDown, Pen, Ban, Lock, Unlock, XCircle, Award, Plus, Minus, Delete, ShieldBan, AlertTriangle, Clock, UserCheck, Activity, Pickaxe, Anvil } from 'lucide-react';
import '../../styles/Dashboard_Users.scss';

const Users = ({ users, loading, updateUser, deleteUser }) => {
    const [listedUsers, setListedUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showUserDetails, setShowUserDetails] = useState(false);

    useEffect(() => {
        setListedUsers(users);
    }, [users]);

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filteredUsers = users.filter(user => user.name.toLowerCase().includes(searchTerm));
        setListedUsers(filteredUsers);
    }

    const handleFilter = (e) => {
        const filter = e.target.value;
        const filteredUsers = users.filter(user => filter === '' || user.role === filter);
        setListedUsers(filteredUsers);
    }

    const handleSort = (e) => {
        const sort = e.target.value;
        const sortedUsers = [...users].sort((a, b) => {
            if (sort === 'tokens_left') return b.tokens_left - a.tokens_left;
            if (sort === 'level') return b.level - a.level;
            return 0;
        });
        setListedUsers(sortedUsers);
    }

    const handleUserSelect = (user) => {
        setSelectedUser(user);
        setShowUserDetails(true);
    }

    const handleUserAction = (action) => {
        if (!selectedUser) return;

        let updatedUser = { ...selectedUser };
        let updatedSecurity = { ...selectedUser.security };

        switch (action) {
            case 'ban':
                updatedSecurity.accountStatus = updatedSecurity.accountStatus === 'suspended' ? 'active' : 'suspended';
                break;
            case 'lock':
                updatedSecurity.isLocked = !updatedSecurity.isLocked;
                break;
            case 'tempLock':
                updatedSecurity.isTempLocked = !updatedSecurity.isTempLocked;
                updatedSecurity.tempLockEndTime = updatedSecurity.isTempLocked ? new Date(Date.now() + 24 * 60 * 60 * 1000) : null;
                break;
            case 'resetPassword':
                updatedSecurity.passwordResetRequested = true;
                break;
            case 'delete':
                deleteUser(selectedUser._id);
                return;
        }

        updatedUser.security = updatedSecurity;
        updateUser(updatedUser);
        setSelectedUser(updatedUser);
    }

    const handleTokenChange = (amount) => {
        if (!selectedUser) return;
        let updatedUser = { ...selectedUser, tokens_left: selectedUser.tokens_left + amount };
        updateUser(updatedUser);
        setSelectedUser(updatedUser);
    }

    const handleExperienceChange = (amount) => {
        if (!selectedUser) return;
        let updatedUser = { ...selectedUser, experience: selectedUser.experience + amount };
        updateUser(updatedUser);
        setSelectedUser(updatedUser);
    }

    const renderSecurityInfo = (security) => {
        return (
            <div className="security-info">
                <h3>Informations de sécurité</h3>
                <p><AlertTriangle size={16} /> Statut du compte: {security.accountStatus}</p>
                <p><Lock size={16} /> Verrouillé: {security.isLocked ? 'Oui' : 'Non'}</p>
                <p><Clock size={16} /> Verrouillage temporaire: {security.isTempLocked ? 'Oui' : 'Non'}</p>
                {security.isTempLocked && security.tempLockEndTime && (
                    <p><Clock size={16} /> Fin du verrouillage temporaire: {new Date(security.tempLockEndTime).toLocaleString()}</p>
                )}
                <p><Activity size={16} /> Tentatives de connexion échouées: {security.loginAttempts}</p>
                <p><Shield size={16} /> Authentification à deux facteurs: {security.hasTwoFactorAuth ? 'Activée' : 'Désactivée'}</p>
                <p><UserCheck size={16} /> Dernière connexion IP: {security.lastIP}</p>
                <p><Clock size={16} /> Dernier changement de mot de passe: {new Date(security.lastPasswordChange).toLocaleString()}</p>
            </div>
        );
    }

    return (
        <div className="users-container">
            {loading ? (
                <p>Chargement des utilisateurs...</p>
            ) : (
                <>
                    <div className="users-filters">
                        <input type="text" placeholder="Rechercher un utilisateur" className="users-search" onChange={handleSearch} />
                        <select className="users-filter" onChange={handleFilter}>
                            <option value="">Tous les utilisateurs</option>
                            <option value="admin">Administrateurs</option>
                            <option value="user">Utilisateurs</option>
                        </select>
                        <select className="users-sort" onChange={handleSort}>
                            <option value="">Trier par</option>
                            <option value="tokens_left">Tokens restants</option>
                            <option value="level">Niveau</option>
                        </select>
                    </div>
                    <div className="users-content">
                        <ul className="users-list">
                            {listedUsers.map((user, index) => (
                                <li key={index} onClick={() => handleUserSelect(user)}>
                                    <div className="user-icon">
                                        {user.role === "admin" ? (
                                            <Shield size={32} color="#f85149" />
                                        ) : (
                                            <User size={32} color="#2c748a" />
                                        )}
                                    </div>
                                    <div className="user-info">
                                        <div className="user-info-header">
                                            <span className={`user-name ${user.role}`}>{user.name}</span>
                                            <span className={`user-role ${user.role}`}>{user.role}</span>
                                        </div>
                                        <div className="user-info-body">
                                            <span className="user-level"><FlaskRound size={16} /> Lvl.{user.level}</span>
                                            <span className="user-tokens-left"><Coins size={16} /> {user.tokens_left} restants</span>
                                            <span className="user-tokens-spent"><TrendingDown size={16} /> {user.tokens_spent} utilisés</span>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {showUserDetails && selectedUser && (
                            <div className="user-details">
                                <h2>{selectedUser.name}</h2>
                                <p><Anvil size={16} /> Role: {selectedUser.role}</p>
                                <p><FlaskRound size={16} /> Niveau: {selectedUser.level}</p>
                                <p><Pickaxe size={16} /> Experience: {selectedUser.experience}</p>
                                <p><Coins size={16} /> Tokens restants: {selectedUser.tokens_left}</p>
                                <p><TrendingDown size={16} /> Tokens utilisés: {selectedUser.tokens_spent}</p>
                                {renderSecurityInfo(selectedUser.security)}
                                <div className="user-actions">
                                    <button onClick={() => handleUserAction('ban')}>
                                        {selectedUser.security.accountStatus === 'suspended' ? <Unlock size={24} /> : <Ban size={24} />}
                                        {selectedUser.security.accountStatus === 'suspended' ? 'Débannir' : 'Bannir'}
                                    </button>
                                    <button onClick={() => handleUserAction('lock')}>
                                        {selectedUser.security.isLocked ? <Unlock size={24} /> : <Lock size={24} />}
                                        {selectedUser.security.isLocked ? 'Déverrouiller' : 'Verrouiller'}
                                    </button>
                                    <button onClick={() => handleUserAction('tempLock')}>
                                        {selectedUser.security.isTempLocked ? <Unlock size={24} /> : <Clock size={24} />}
                                        {selectedUser.security.isTempLocked ? 'Déverrouiller temporairement' : 'Verrouiller temporairement'}
                                    </button>
                                    <button onClick={() => handleUserAction('resetPassword')}>
                                        <Pen size={24} />
                                        Réinitialiser le mot de passe
                                    </button>
                                    <button onClick={() => handleUserAction('delete')}>
                                        <ShieldBan size={24} />
                                        Supprimer le compte
                                    </button>
                                </div>
                                <div className="user-modifications">
                                    <div className="token-modification">
                                        <button onClick={() => handleTokenChange(-10)}><Minus size={24} /></button>
                                        <span>Tokens</span>
                                        <button onClick={() => handleTokenChange(10)}><Plus size={24} /></button>
                                    </div>
                                    <div className="experience-modification">
                                        <button onClick={() => handleExperienceChange(-100)}><Minus size={24} /></button>
                                        <span>Expérience</span>
                                        <button onClick={() => handleExperienceChange(100)}><Plus size={24} /></button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Users;