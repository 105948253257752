import React, { useState, useEffect } from 'react'
import '../styles/UserParams.scss'
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { Clock, BarChart, User, Settings, Mail, Phone, Lock, Globe, Bell, Shield, Key, Database, Zap, Rocket, FileText, Search, Verified, Star, Hammer, Eye, MailCheck, PillBottle } from 'lucide-react';
import { FaMagic, FaMoneyBill } from 'react-icons/fa';
import { getLink } from '../utils/pointer';

const UserParams = ({ selectedPage }) => {
    const { user, logout } = useUser();
    const [selected, setSelected] = useState(selectedPage);
    const [isDeveloperMode, setIsDeveloperMode] = useState(user?.settings?.developerMode || false);
    const [isTwoFactorAuth, setIsTwoFactorAuth] = useState(user?.settings?.twoFactorAuth || false);
    const [isSaveSearches, setIsSaveSearches] = useState(user?.settings?.save_searches || false);
    const [historyDuration, setHistoryDuration] = useState(user?.settings?.history_duration || 30);
    const navigate = useNavigate();

    useEffect(() => {
        setSelected(selectedPage);
    }, [selectedPage]);

    const handlePageChange = (page) => {
        setSelected(page);
    };

    const showApiDocs = () => {
        navigate('/api');
    }

    const toggleDeveloperMode = () => {
        setIsDeveloperMode(!isDeveloperMode);

    };

    const toggleTwoFactorAuth = () => {
        setIsTwoFactorAuth(!isTwoFactorAuth);
    }

    const toggleSaveSearches = () => {
        setIsSaveSearches(!isSaveSearches);
    }

    const askToRemove = () => {
        const wait = async () => {
            const response = await fetch(getLink() + '/remove-account', {
                method: 'GET',
                headers: {
                    // 'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('db_token'),
                },
            });

            if (!response.ok) {
            }

            logout()
        }

        wait()
    }

    // set tab title
    document.title = 'OzZzint.fr ▪ Paramètres';

    const renderPage = () => {
        switch (selected) {
            case 'history':
                return (
                    <div className='history-page'>
                        <div className='history-page-header'>
                            <h1><Clock size={24} /> Historique</h1>
                            <button>Tout sélectionner</button>
                        </div>
                        <div className='history-list'>
                            <p>Cette fonctionnalité est en cours de développement.</p>
                        </div>
                        <button className='load-more'>Charger plus</button>
                    </div>
                )
            case 'api':
                return (isDeveloperMode ? (
                    <div className='api-page'>
                        <div className='api-page-header'>
                            <h1><Zap size={24} /> Accès API</h1>
                        </div>
                        <div className='api-key-section'>
                            <h2><Key size={24} /> Clé API</h2>
                            <div className='api-key-display'>
                                <input type="password" value="••••••••••••••••" readOnly />
                                <button><Key size={20} /> Afficher</button>
                                <button><Database size={20} /> Régénérer</button>
                            </div>
                        </div>
                        <div className='api-usage'>
                            <h2><BarChart size={24} /> Utilisation de l'API</h2>
                            <div className='usage-stats'>
                                <div className='stat-item'>
                                    <h3><Rocket size={24} /> Requêtes ce mois</h3>
                                    <p>1,234 / 10,000</p>
                                    <span>Il est possible de faire des requêtes supplémentaires en augmentant ses crédits.</span>
                                </div>
                                <div className='stat-item'>
                                    <h3><Rocket size={24} /> Limite quotidienne</h3>
                                    <p>500</p>
                                    <span>Il est possible de faire des requêtes supplémentaires en augmentant ses crédits.</span>
                                </div>
                            </div>
                        </div>
                        <div className='api-documentation'>
                            <h2><FileText size={24} /> Documentation</h2>
                            <a onClick={showApiDocs} className='doc-link'>Voir la documentation complète</a>
                        </div>
                    </div>
                ) : null)
            case 'account':
                return (
                    <div className='profile-page'>
                        <div className='profile-page-header'>
                            <h1><User size={24} /> Compte</h1>
                        </div>
                        <div className='profile-info'>
                            <div className='info-item'>
                                <User size={38} />

                                <p>{user?.user.name || "Aucun"}</p>
                                <div className='profile-info-xp'>
                                    <p>Lvl. {user?.user.level || 1}</p>
                                    <p>Exp. {user?.user.experience || 0}</p>
                                </div>

                            </div>
                            <div className='info-item'>
                                <Verified size={38} />
                                <p>{user?.user.isVerified ? "Vérifié" : "Non vérifié"}</p>
                                <p>{user?.user.createdAt ? user?.user.createdAt.split('T')[0] : "Aucune date"}</p>
                            </div>
                            <div className='info-item'>
                                <Star size={38} />
                                <p>Disponibles : {user?.user.tokens_left || 0}</p>
                                <p>Dépensés : {user?.user.tokens_spent || 0}</p>
                            </div>
                            <div className='info-item'>
                                <Lock size={38} />
                                <button>Changer le mot de passe</button>
                            </div>
                        </div>


                        <div className='profile-info'>
                            <div className='info-item'>
                                <Shield size={38} />
                                <div>
                                    <p>{user?.account.isLocked || user?.account.isTempLocked ? "Verrouillé" : "Non verrouillé"}</p>
                                </div>
                            </div>
                            <div className='info-item'>
                                <Zap size={38} />
                                <div>
                                    <p>Punitions: {user?.account.punishments.length || 0}</p>
                                </div>
                            </div>
                        </div>
                        <div className='account-actions'>
                            <button className='danger-action' onClick={askToRemove}>Supprimer le compte</button>
                            <button className='disconnect-action'>Se déconnecter</button>
                        </div>
                    </div>
                )
            default:
                return (
                    <div className='settings-page'>
                        <div className='settings-page-header'>
                            <h1><Settings size={24} /> Paramètres</h1>
                        </div>
                        <div className='settings-section'>
                            <h2>Préférences générales</h2>
                            <div className='setting-item'>
                                <Globe size={20} />
                                <h3>Langue</h3>
                                <select>
                                    <option>Français</option>
                                    <option>English</option>
                                    <option>Español</option>
                                </select>
                            </div>
                            <div className='setting-item'>
                                <Bell size={20} />
                                <h3>Notifications</h3>
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='settings-section'>
                            <h2>Sécurité</h2>
                            <div className='setting-item'>
                                <Shield size={20} />
                                <h3>Authentification à deux facteurs</h3>
                                <label className="switch">
                                    <input type="checkbox" checked={isTwoFactorAuth} onChange={toggleTwoFactorAuth} />
                                    <span className="slider round"></span>
                                </label>
                            </div>

                            <div className='setting-item'>
                                <Lock size={20} />
                                <h3>Développeur</h3>
                                <label className="switch">
                                    <input type="checkbox" checked={isDeveloperMode} onChange={toggleDeveloperMode} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='settings-section'>
                            <h2>Confidentialité</h2>
                            <div className='setting-item'>
                                <Eye size={20} />
                                <h3>Conserver un historique de recherche</h3>
                                <label className="switch">
                                    <input type="checkbox" checked={isSaveSearches} onChange={toggleSaveSearches} />
                                    <span className="slider round"></span>
                                </label>
                            </div>

                            {isSaveSearches && (
                                <div className='setting-item'>
                                    <Eye size={20} />
                                    <h3>Durée de conservation</h3>
                                    <input type="number" value={historyDuration} onChange={(e) => setHistoryDuration(e.target.value)} />
                                    <span>Jours</span>
                                </div>
                            )}

                            <h3 className='visibility-title'>Visibilité de vos informations
                                <span className='visibility-subtitle'> (Publique = Activé ou Privé = Désactivé)</span>
                            </h3>
                            <hr className='visibility-separator' />
                            <div className='visibility-section'>
                                <div className='visibility-item'>
                                    <User size={20} />
                                    <h3>Votre pseudo</h3>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                                <div className='visibility-item'>
                                    <MailCheck size={20} />
                                    <h3>Votre email</h3>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                                <div className='visibility-item'>
                                    <PillBottle size={20} />
                                    <h3>Votre expérience</h3>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                                <div className='visibility-item'>
                                    <FaMagic size={20} />
                                    <h3>Votre niveau</h3>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                                <div className='visibility-item'>
                                    <FaMoneyBill size={20} />
                                    <h3>Vos tokens dépensés</h3>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }
    }

    return (
        <div className='user-params'>
            <div className='user-params-content'>
                <div className='user-params-tabs'>
                    <button onClick={() => handlePageChange('account')}><User size={24} /> Compte</button>
                    <button onClick={() => handlePageChange('history')}><Clock size={24} /> Historique</button>
                    {isDeveloperMode ? <button onClick={() => handlePageChange('api')}><Zap size={24} /> Accès API</button> : null}
                    <button onClick={() => handlePageChange('settings')}><Settings size={24} /> Paramètres</button>
                </div>
                <div className='user-params-content-page'>
                    {renderPage()}
                </div>
            </div>
        </div>
    )
}

export default UserParams