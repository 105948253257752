import React, { useState, useEffect } from 'react';
import { FileWarning, Key, UserPlus } from 'lucide-react';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import Seo from '../components/Seo';
import logo from '../img/register.png';
import '../styles/Login.scss';

const Register = () => {
  const { user, register, isAuth } = useUser();
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [currentView, setCurrentView] = useState('login');
  const [sponsorCode, setSponsorCode] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      navigate('/');
    }
  }, [isAuth]);


  const handleRegister = async () => {
    if (name.trim() === '') {
      setError('Veuillez entrer votre nom d\'utilisateur');
      return;
    }

    // verify that name is only letters and Numbers
    const nameRegex = /^[a-zA-Z0-9]+$/;
    if (!nameRegex.test(name)) {
      setError('Le nom d\'utilisateur ne peut contenir que des lettres et des chiffres');
      return;
    }

    // max length 32 characters
    if (name.length > 32) {
      setError('Le nom d\'utilisateur ne peut contenir que 32 caractères');
      return;
    }

    // Verify that there is a password and that not empty
    if (password.trim() === '') {
      setError('Veuillez entrer votre mot de passe');
      return;
    }

    // le mot de passe doit contenir au moins 8 caractères
    if (password.length < 8) {
      setError('Le mot de passe doit contenir au moins 8 caractères');
      return;
    }

    // Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      setError('Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial');
      return;
    }

    // max length 80 characters
    if (password.length > 80) {
      setError('Le mot de passe ne peut contenir que 80 caractères');
      return;
    }

    // Vérifier le second mot de passe
    if (password !== passwordConfirm) {
      setError('Les mots de passe ne correspondent pas');
      return;
    }

    // Verify that the email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('L\'email est invalide');
      return;
    }

    const isRegister = await register(name, email, password, sponsorCode);
    if (isRegister) {
      return navigate('/login')
    } else {
      setError('Une erreur est survenue lors de l\'inscription');
    }
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
    setError('');
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setError('');
  };

  const handleChangePasswordConfirm = (e) => {
    setPasswordConfirm(e.target.value);
    setError('');
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const handleChangeTermsAccepted = (e) => {
    setTermsAccepted(e.target.checked);
    setError('');
  };

  const handleChangeSponsorCode = (e) => {
    setSponsorCode(e.target.value);
    setError('');
  };



  useEffect(() => {
    if (error !== '') {
    }
  }, [error]);

  const renderLoginView = () => (
    <>

      <div className='header-setup'>
        <img src={logo} alt="Logo" className="logo" />
      </div>
      {error !== '' && (
        <div className="error">
          <FileWarning size={24} />
          <span>{error}</span>
        </div>
      )}
      <div className="setup-step">
        <p className='login-step'>Nom d'utilisateur</p>
        <input type="text" placeholder="Nom d'utilisateur" onChange={handleChangeName} />

        <p className='login-step'>Email</p>
        <input type="email" placeholder="Email" onChange={handleChangeEmail} />

        <p className='login-step'>Mot de passe</p>
        <input type="password" placeholder="Mot de passe" onChange={handleChangePassword} />

        <p className='login-step'>Confirmation du mot de passe</p>
        <input type="password" placeholder="Confirmation du mot de passe" onChange={handleChangePasswordConfirm} />

        <p className='login-step'>Code Sponsor (optionnel)</p>
        <input type="text" placeholder="Code Sponsor" onChange={handleChangeSponsorCode} />

        <button className="next-button" onClick={() => handleRegister()}>
          S'inscrire
        </button>
      </div>

      <div className='footer-login'>
        <a onClick={() => navigate('/login')}>Déjà un compte ? Se connecter</a>
      </div>
    </>
  );

  const renderMfaView = () => (
    <>
      <div className='header-setup'>
        <img src={logo} alt="Logo" className="logo" />
        <h3>Vérifier votre compte</h3>
        <p className='welcome'>
          <strong>Entrer le code</strong> envoyé à votre email ou depuis l'application.
        </p>
      </div>
      <div className="setup-step">
        <input type="text" placeholder="Code" />
      </div>
      <div className='footer-login'>
        <button className="next-button">
          Verify
        </button>
      </div>
    </>
  );

  return (
    <>
      <Seo
        title="Inscription | Rejoignez la Communauté Ozzzint"
        description="Créez votre compte Ozzzint et accédez à nos outils d'OSINT. Rejoignez une communauté passionnée d'experts en investigation numérique."
        canonicalUrl="https://ozzzint.fr/register"
        robots="noindex, nofollow"
        author="Ozzzint"
      />
      <div className="setup-page">
        <div className="setup-container">
          {currentView === 'login' && renderLoginView()}
          {currentView === 'mfa' && renderMfaView()}
        </div>
      </div>
    </>
  );
}

export default Register;