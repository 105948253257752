import React from 'react'
import Seo from '../components/Seo';
import '../styles/Legals.scss';
function Legals() {
    const cgu = [
        {
            title: "Présentation du site",
            content: [
                `Ozzzint.fr (ci-après "le Site") est édité par la société Ds-Holding (ci-après "l'Éditeur"), société enregistrée en France. Le Site permet aux utilisateurs d'effectuer des recherches sur des noms de domaine, adresses IP, pseudos, adresses e-mail, et numéros de téléphone à des fins d'investigation numérique.`,
            ]
        },
        {
            title: "Acceptation des Conditions Générales d'Utilisation (CGU)",
            content: [
                `L'accès et l'utilisation des services proposés sur le Site sont soumis à l'acceptation inconditionnelle des présentes Conditions Générales d'Utilisation (CGU). En accédant au Site, vous reconnaissez avoir lu, compris et accepté les présentes CGU sans réserve. Si vous n'acceptez pas ces CGU, vous devez cesser immédiatement toute utilisation du Site.`
            ]
        },
        {
            title: "Accès au site",
            content: [
                `L'accès au Site est gratuit pour les utilisateurs disposant d'un accès à Internet. Toutefois, certaines fonctionnalités peuvent être payantes ou réservées aux utilisateurs enregistrés.`,
                `L'Éditeur se réserve le droit de modifier, suspendre ou interrompre, à tout moment, tout ou partie du Site sans préavis et sans que cela ne puisse donner lieu à une quelconque indemnisation.`
            ]
        },
        {
            title: "Utilisation des services",
            content: [
                `L'utilisateur s'engage à utiliser le Site de manière légale et en conformité avec les lois françaises et européennes. En particulier, l'utilisateur s'interdit de :`,
                `Utiliser le Site pour toute activité illégale ou qui violerait les droits des tiers.`,
                `Accéder à des données personnelles de manière frauduleuse ou sans l'autorisation légale.`,
                `Utiliser les informations obtenues via le Site à des fins nuisibles ou non conformes à la réglementation (harcèlement, fraude, usurpation d’identité, etc.).`,
                `Tenter de porter atteinte à la sécurité ou à l'intégrité du Site, notamment en introduisant des virus ou tout autre code malveillant.`
            ]
        },
        {
            title: "Responsabilité et garantie",
            content: [
                `L'Éditeur met en œuvre tous les moyens raisonnables pour s'assurer que les informations disponibles sur le Site sont fiables et exactes. Toutefois, il ne peut garantir l'exactitude, l'exhaustivité ou l'actualité des informations fournies, en particulier des résultats de recherche obtenus via le Site.`,
                `En conséquence, l'Éditeur décline toute responsabilité en cas de dommages directs ou indirects, matériels ou immatériels, découlant de l'utilisation des services proposés sur le Site, y compris en cas de :`,
                `Utilisation de données incorrectes, inexactes ou obsolètes.`,
                `Interruption des services, défaut de performance, ou indisponibilité du Site.`,
                `Utilisation frauduleuse des services ou des informations collectées via le Site.`,
                `L'utilisation des services du Site se fait aux risques et périls de l'utilisateur. Le Site est fourni "tel quel", sans aucune garantie d'aucune sorte, explicite ou implicite, notamment concernant sa disponibilité, son adéquation à un usage particulier, ou l'absence de virus.`
            ]
        },
        {
            title: "Collecte et traitement des données personnelles",
            content: [
                `Ozzzint.fr traite les données personnelles des utilisateurs conformément aux dispositions du Règlement Général sur la Protection des Données (RGPD). Les informations collectées lors de l'utilisation des services sont destinées à améliorer les performances du Site et garantir une meilleure expérience utilisateur.`,
                `L'utilisateur s'engage à fournir des informations exactes et à jour. L'Éditeur s'engage à respecter la confidentialité et la sécurité des données personnelles des utilisateurs.`
            ]
        },
        {
            title: "Propriété intellectuelle",
            content: [
                `Le contenu du Site, y compris mais sans s'y limiter, les textes, graphiques, logos, icônes, images, vidéos, sons, et logiciels, est la propriété exclusive de l'Éditeur ou de ses partenaires et est protégé par les lois en vigueur sur la propriété intellectuelle.`,
                `Toute reproduction, modification, distribution ou exploitation non autorisée des éléments du Site, sans l'accord exprès de l'Éditeur, est strictement interdite et pourra faire l'objet de poursuites judiciaires.`
            ]
        },
        {
            title: "Liens hypertextes et services tiers",
            content: [
                `Le Site peut contenir des liens vers des sites web tiers ou proposer des services fournis par des partenaires extérieurs. L'Éditeur décline toute responsabilité quant au contenu ou aux pratiques de ces sites ou services tiers. L'utilisateur est invité à lire attentivement les conditions d'utilisation et les politiques de confidentialité de ces services avant de les utiliser.`
            ]
        },
        {
            title: "Modifications des CGU",
            content: [
                `L'Éditeur se réserve le droit de modifier les présentes CGU à tout moment. Les utilisateurs seront informés des modifications importantes par tout moyen jugé approprié. L'utilisateur est invité à consulter régulièrement les CGU afin de prendre connaissance des éventuelles modifications.`
            ]
        },
        {
            title: "Droit applicable et juridiction compétente",
            content: [
                `Les présentes CGU sont régies par la législation française. En cas de litige relatif à l'interprétation ou à l'exécution des présentes CGU, les parties s'efforceront de résoudre le différend à l'amiable. À défaut, le litige sera porté devant les tribunaux compétents du ressort de Paris, conformément à la législation française.`
            ]
        },
        {
            title: "Contact",
            content: [
                `Pour toute question ou demande d'information concernant les présentes CGU, vous pouvez contacter l'Éditeur à l'adresse suivante : contact@ds-holding.fr.`
            ]
        }
    ]

    const policy = [
        {
            title: "",
            content: [
                `Chez Ozzzint.fr, nous attachons une importance particulière à la protection de vos données personnelles. Cette Politique de Confidentialité explique quelles données sont collectées, comment elles sont utilisées et protégées, et les droits dont vous disposez conformément au Règlement Général sur la Protection des Données (RGPD).`
            ]
        },
        {
            title: "Responsable du traitement des données",
            content: [
                `Le responsable du traitement des données est la société Ds-Holding, éditrice du site Ozzzint.fr. Pour toute question relative à la gestion de vos données personnelles, vous pouvez nous contacter à l'adresse suivante : contact@ds-holding.fr.`
            ]
        },
        {
            title: "Données collectées",
            content: [
                `Dans le cadre de l'utilisation de notre site et de nos services, nous pouvons collecter les types de données personnelles suivants :`,
                `Données d'identification : adresse e-mail, nom d'utilisateur (pseudonyme) lors de la création d’un compte utilisateur.`,
                `Données de connexion et de navigation : adresse IP, type d’appareil, type de navigateur, durée de la session, pages visitées, historique de recherche.`,
                `Cookies et technologies similaires : fichiers ou technologies de suivi pour analyser l'utilisation du site et améliorer l'expérience utilisateur.`
            ]
        },
        {
            title: "Finalités du traitement",
            content: [
                `Nous utilisons vos données personnelles dans les cas suivants :`,
                `Prestation des services : traitement des requêtes de recherche sur des noms de domaine, IP, pseudos, etc.`,
                `Amélioration du site : optimisation de la performance du site, personnalisation de l’expérience utilisateur, et analyse des comportements de navigation.`,
                `Sécurité : prévention des fraudes, gestion des abus, et protection contre les cyberattaques.`,
                `Obligations légales : respect des obligations légales et réglementaires en matière de protection des données.`
            ]
        },
        {
            title: "Base légale du traitement",
            content: [
                `Nous traitons vos données personnelles sur les bases légales suivantes :`,
                `Consentement : en accédant au site et en utilisant nos services, vous consentez à l'utilisation de vos données dans les conditions précisées.`,
                `Exécution du contrat : traitement nécessaire pour l'exécution de nos services lorsque vous faites appel à nos outils de recherche.`,
                `Obligations légales : respect de la réglementation, notamment en matière de conservation de certaines données.`
            ]
        },
        {
            title: "Cookies et local storage",
            content: [
                `Ozzzint.fr utilise des cookies et des technologies similaires, tels que le local storage, pour collecter des informations concernant votre navigation et améliorer l'expérience utilisateur.`,
                `5.1. Qu'est-ce qu'un cookie ?`,
                `Un cookie est un petit fichier texte déposé sur votre appareil (ordinateur, tablette, smartphone) lors de la visite d’un site web. Il permet de stocker des informations sur la navigation, comme les préférences de langue, les pages visitées ou encore les identifiants de session.`,
                `5.2. Finalités des cookies`,
                `Les cookies que nous utilisons sont classés selon les catégories suivantes :`,
                `Cookies strictement nécessaires : indispensables au fonctionnement du site et à l'accès à certaines fonctionnalités.`,
                `Cookies de performance : utilisés pour recueillir des données sur la manière dont les visiteurs utilisent le site, afin d'améliorer les performances.`,
                `Cookies de fonctionnalité : permettent de mémoriser vos préférences (comme la langue).`,
                `Cookies de ciblage/publicité : utilisés pour proposer des publicités ciblées ou limiter le nombre de fois qu'une publicité vous est montrée.`,
                `5.3. Consentement à l'utilisation des cookies`,
                `Conformément à la réglementation, lors de votre première visite sur Ozzzint.fr, un bandeau vous informe de l'utilisation des cookies. Vous avez la possibilité d'accepter ou de refuser l'utilisation de certains cookies via notre gestionnaire de consentement.`,
                `Vous pouvez à tout moment modifier vos préférences en matière de cookies dans les paramètres de votre navigateur ou via le gestionnaire de cookies disponible sur notre site.`
            ]
        },
        {
            title: "Conservation des données",
            content: [
                `Les données personnelles collectées sont conservées pour la durée strictement nécessaire aux finalités pour lesquelles elles sont traitées, à savoir :`,
                `Données de compte : conservées pendant la durée d’utilisation du compte et jusqu’à sa suppression.`,
                `Données de navigation : conservées pour une durée maximale de 13 mois.`,
                `Données relatives aux obligations légales : conservées aussi longtemps que nécessaire pour répondre aux obligations légales.`
            ]
        },
        {
            title: "Sécurité des données",
            content: [
                `Ozzzint.fr met en œuvre des mesures de sécurité techniques et organisationnelles appropriées pour protéger vos données personnelles contre la destruction, la perte, l'altération, la divulgation non autorisée ou l'accès non autorisé. Ces mesures incluent l'utilisation de protocoles sécurisés (SSL/TLS), des pare-feu, et des systèmes de contrôle d'accès stricts.`
            ]
        },
        {
            title: "Partage et transfert de données",
            content: [
                `Nous ne vendons ni ne louons vos données personnelles à des tiers. Toutefois, nous pouvons être amenés à partager vos données avec :`,
                `Prestataires de services tiers : pour l’hébergement du site, l’analyse de la performance (Google Analytics, par exemple), et autres services techniques nécessaires à l’exploitation du site.`,
                `Autorités publiques : dans le cadre des obligations légales ou à la demande des autorités compétentes (par exemple, en cas de fraude ou d'enquête judiciaire).`,
                `Les données peuvent être transférées hors de l’Union européenne, notamment vers des prestataires situés dans des pays assurant un niveau de protection adéquat ou en conformité avec des garanties appropriées, comme les clauses contractuelles types.`
            ]
        },
        {
            title: "Vos droits",
            content: [
                `Conformément au RGPD, vous disposez des droits suivants concernant vos données personnelles :`,
                `Droit d'accès : obtenir une copie des données personnelles que nous détenons à votre sujet.`,
                `Droit de rectification : demander la correction des données inexactes ou incomplètes.`,
                `Droit à l’effacement : demander la suppression de vos données, sous certaines conditions.`,
                `Droit à la limitation : limiter le traitement de vos données dans certains cas.`,
                `Droit d’opposition : vous opposer au traitement de vos données pour des motifs légitimes ou à des fins de prospection.`,
                `Droit à la portabilité : recevoir vos données dans un format structuré et couramment utilisé.`,
                `Droit de retrait du consentement : retirer à tout moment votre consentement concernant le traitement de vos données basé sur celui-ci.`,
                `Pour exercer ces droits, vous pouvez nous contacter à l’adresse suivante : contact@ds-holding.fr. Vous avez également la possibilité d’introduire une réclamation auprès de la CNIL.`
            ]
        },
        {
            title: "Modifications de la Politique de Confidentialité",
            content: [
                `Nous nous réservons le droit de modifier la présente Politique de Confidentialité à tout moment, notamment en fonction de l'évolution de la législation ou des services proposés sur le site. Nous vous informerons de toute modification importante via le site ou par e-mail.`
            ]
        }
    ]


    return (
        <>
            <Seo
                title="Mentions Légales et CGU | Ozzzint.fr"
                description="Consultez nos conditions générales d'utilisation, mentions légales et politique de confidentialité. Ozzzint s'engage pour la transparence et la protection de vos données."
                canonicalUrl="https://ozzzint.fr/legal"
                keywords="mentions légales OSINT, CGU cybersécurité, politique confidentialité, RGPD"
                author="Ozzzint"
                robots="noindex, follow"
            />
            <div className='legals'>
                {/* <Navbar /> */}
                <div className='legals-header'>
                    <h1>Mentions Légales</h1>
                    <p>Dernière mise à jour : 2024-02-01</p>
                </div>


                <div className='legals-content'>
                    <h1>Conditions Générales d'Utilisation</h1>
                    {cgu.map((item, index) => (
                        <div key={index} className='legals-item'>
                            {item.title === "" ? <h2>{item.title}</h2> : <h2><span>•</span> {item.title}</h2>}
                            <div>{item.content.length > 0 ? item.content.map((content, index) => <p key={index}>{content}</p>) : <p>Aucun contenu</p>}</div>
                        </div>
                    ))}
                </div>

                <div className='legals-content'>
                    <h1>Politique de Confidentialité</h1>
                    {policy.map((item, index) => (
                        <div key={index} className='legals-item'>
                            {item.title === "" ? <h2>{item.title}</h2> : <h2><span>•</span> {item.title}</h2>}
                            <div>{item.content.length > 0 ? item.content.map((content, index) => <p key={index}>{content}</p>) : <p>Aucun contenu</p>}</div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Legals;
