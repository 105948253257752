import React, { useState, useEffect } from 'react';
import { Shield, Lock, UserPlus, UserMinus, List, Activity, Play, Settings, AlertTriangle } from 'lucide-react';
import '../../styles/Security.scss'

const Security = () => {
    const [firewallStatus, setFirewallStatus] = useState(false);
    const [firewallRules, setFirewallRules] = useState([]);
    const [whitelist, setWhitelist] = useState([]);
    const [blacklist, setBlacklist] = useState([]);
    const [apiRoutes, setApiRoutes] = useState([]);
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [securityScanStatus, setSecurityScanStatus] = useState('idle');

    useEffect(() => {
        // Simuler le chargement des données initiales
        fetchInitialData();
    }, []);

    const fetchInitialData = () => {
        // Simulation de l'appel API pour récupérer les données initiales
        setFirewallRules([
            { id: 1, name: 'Block Port 80', active: true },
            { id: 2, name: 'Allow HTTPS', active: true },
        ]);
        setWhitelist(['192.168.1.1', '10.0.0.1']);
        setBlacklist(['1.1.1.1', '2.2.2.2']);
        setApiRoutes([
            { path: '/api/users', method: 'GET', status: 'active' },
            { path: '/api/products', method: 'POST', status: 'deprecated' },
        ]);
        setRoles(['admin', 'user', 'guest']);
        setPermissions(['read', 'write', 'delete']);
    };

    const toggleFirewall = () => {
        setFirewallStatus(!firewallStatus);
        // Ici, vous appelleriez normalement une API pour activer/désactiver le firewall
    };

    const addFirewallRule = (rule) => {
        setFirewallRules([...firewallRules, { id: Date.now(), ...rule, active: true }]);
    };

    const toggleFirewallRule = (id) => {
        setFirewallRules(firewallRules.map(rule =>
            rule.id === id ? { ...rule, active: !rule.active } : rule
        ));
    };

    const addToList = (list, item) => {
        if (list === 'whitelist') {
            setWhitelist([...whitelist, item]);
        } else {
            setBlacklist([...blacklist, item]);
        }
    };

    const removeFromList = (list, item) => {
        if (list === 'whitelist') {
            setWhitelist(whitelist.filter(i => i !== item));
        } else {
            setBlacklist(blacklist.filter(i => i !== item));
        }
    };

    const updateApiRouteStatus = (path, newStatus) => {
        setApiRoutes(apiRoutes.map(route =>
            route.path === path ? { ...route, status: newStatus } : route
        ));
    };

    const addRole = (role) => {
        setRoles([...roles, role]);
    };

    const addPermission = (permission) => {
        setPermissions([...permissions, permission]);
    };

    const startSecurityScan = () => {
        setSecurityScanStatus('scanning');
        // Simuler un scan de sécurité
        setTimeout(() => {
            setSecurityScanStatus('completed');
        }, 5000);
    };

    return (
        <div className="security-panel">
            <h1><Shield /> Panel de Sécurité</h1>

            <h2><Lock /> Firewall</h2>
            <section className="firewall-section">
                <div className="firewall-toggle">
                    <label>
                        <input
                            type="checkbox"
                            checked={firewallStatus}
                            onChange={toggleFirewall}
                        />
                        Firewall {firewallStatus ? 'Activé' : 'Désactivé'}
                    </label>
                </div>
                <div className="firewall-rules">
                    <h3>Règles du Firewall</h3>
                    <ul>
                        {firewallRules.map(rule => (
                            <li key={rule.id}>
                                <span>{rule.name}</span>
                                <button onClick={() => toggleFirewallRule(rule.id)}>
                                    {rule.active ? 'Désactiver' : 'Activer'}
                                </button>
                            </li>
                        ))}
                    </ul>
                    <button onClick={() => addFirewallRule({ name: 'Nouvelle règle' })}>
                        Ajouter une règle
                    </button>
                </div>
            </section>

            <h2><UserPlus /> Listes d'accès</h2>
            <section className="access-lists">
                <div className="whitelist">
                    <h3>Whitelist</h3>
                    <ul>
                        {whitelist.map(item => (
                            <li key={item}>
                                {item}
                                <button onClick={() => removeFromList('whitelist', item)}>Supprimer</button>
                            </li>
                        ))}
                    </ul>
                    <input type="text" placeholder="Ajouter à la whitelist"
                        onKeyPress={(e) => e.key === 'Enter' && addToList('whitelist', e.target.value)} />
                </div>
                <div className="blacklist">
                    <h3>Blacklist</h3>
                    <ul>
                        {blacklist.map(item => (
                            <li key={item}>
                                {item}
                                <button onClick={() => removeFromList('blacklist', item)}>Supprimer</button>
                            </li>
                        ))}
                    </ul>
                    <input type="text" placeholder="Ajouter à la blacklist"
                        onKeyPress={(e) => e.key === 'Enter' && addToList('blacklist', e.target.value)} />
                </div>
            </section>

            <h2><List /> Routes API</h2>
            <section className="api-routes">
                <table>
                    <thead>
                        <tr>
                            <th>Chemin</th>
                            <th>Méthode</th>
                            <th>Statut</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apiRoutes.map(route => (
                            <tr key={route.path}>
                                <td>{route.path}</td>
                                <td>{route.method}</td>
                                <td>{route.status}</td>
                                <td>
                                    <select
                                        value={route.status}
                                        onChange={(e) => updateApiRouteStatus(route.path, e.target.value)}
                                    >
                                        <option value="active">Active</option>
                                        <option value="deprecated">Dépréciée</option>
                                        <option value="disabled">Désactivée</option>
                                    </select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>

            <section className="security-scan">
                <h2><Activity /> Analyse de Sécurité</h2>
                <button onClick={startSecurityScan} disabled={securityScanStatus === 'scanning'}>
                    {securityScanStatus === 'scanning' ? 'Analyse en cours...' : 'Lancer une analyse'}
                </button>
                {securityScanStatus === 'completed' && (
                    <div className="scan-results">
                        <h3><AlertTriangle /> Résultats de l'analyse</h3>
                        <p>Analyse terminée. Aucune vulnérabilité critique détectée.</p>
                    </div>
                )}
            </section>
        </div>
    );
};

export default Security;