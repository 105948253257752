import React from 'react';
import { Shield, Globe, Lock, Server, Database, Code, AlertTriangle, CodeIcon, Book } from 'lucide-react';
import '../styles/DomainResults.scss';

const DomainResults = ({ data, domain, duration }) => {
    const renderSubDomains = (subdomains) => {
        return (
            <div className="domain-section">
                <div className="domain-section-icon">
                    <Globe size={20} />
                    <h4>Sous-domaines</h4>
                </div>

                <div className="domain-section-content">
                    <ul>
                        {subdomains.map((subdomain, index) => (
                            <li key={index}>{subdomain}</li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    };

    const renderWhois = (whois, icon) => {
        return (
            <div className="domain-section">
                <div className="domain-section-icon">
                    {icon}
                    <h4>WHOIS</h4>
                </div>

                <div className="domain-section-content">
                    <h4>Enregistré par :</h4>
                    <p>{whois.registrar}</p>

                    <h4>Date de création :</h4>
                    <p>{whois.creation_date.startsWith("[datetime") ? "Date de création non disponible" : whois.creation_date}</p>
                    <h4>Date d'expiration :</h4>
                    <p>{whois.expiration_date.startsWith("[datetime") ? "Date d'expiration non disponible" : whois.expiration_date}</p>
                </div>
            </div>
        );
    };

    const renderXSS = (title, xss, icon) => {
        return (
            <div className="domain-section">
                <div className="domain-section-icon">
                    {icon}
                    <h4>{title}</h4>
                </div>

                <div className="domain-section-content">
                    <p>Est vulnérable: {xss.vulnerable ? "Oui" : "Non"}</p>
                    <p>Payloads : {xss.payloads.length}</p>
                    <ul>
                        {xss.payloads.map((issue, index) => (
                            <li key={index}><AlertTriangle size={16} /> {issue}</li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    };

    const renderSQLInjection = (title, sqlInjection, icon) => {
        return (
            <div className="domain-section">
                <div className="domain-section-icon">
                    {icon}
                    <h4>{title}</h4>
                </div>

                <div className="domain-section-content">
                    <p>Est vulnérable: {sqlInjection.vulnerable ? "Oui" : "Non"}</p>
                    <p>Payloads : {sqlInjection.payloads.length}</p>
                    <ul>
                        {sqlInjection.payloads.map((issue, index) => (
                            <li key={index}><AlertTriangle size={16} /> {issue}</li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    };


    const renderDNS = (title, dns, icon) => {
        return (
            <div className="domain-section">
                <div className="domain-section-icon">
                    {icon}
                    <h4>{title}</h4>
                </div>

                <div className="domain-section-content">
                    <h4>Records trouvés: {dns.length}</h4>

                    <p>A trouvés:</p>
                    <ul>
                        {dns.A.map((issue, index) => (
                            <li key={index}>{issue}</li>
                        ))}
                    </ul>

                    <p>MX trouvés:</p>
                    <ul>
                        {dns.MX.map((issue, index) => (
                            <li key={index}>{issue}</li>
                        ))}
                    </ul>

                    <p>TXT trouvés:</p>
                    <ul>
                        {dns.TXT.map((issue, index) => (
                            <li key={index}>{issue}</li>
                        ))}
                    </ul>
                </div>

            </div>
        );
    };

    const renderWaf = (title, waf, icon) => {
        return (
            <div className="domain-section">
                <div className="domain-section-icon">
                    {icon}
                    <h4>{title}</h4>
                </div>
                <div className="domain-section-content">
                    <p>WAF Détecté: {waf.waf_detected ? "Oui" : "Non"}</p>
                </div>

            </div>
        )
    }

    const renderCMS = (title, cms, icon) => {
        return (
            <div className="domain-section">
                <div className="domain-section-icon">
                    {icon}
                    <h4>{title}</h4>
                </div>

                <div className="domain-section-content">
                    <h4>CMS Détecté:</h4>

                    {cms.cms === "Unknown" || cms.cms === "Error" ? (
                        <>
                            <p>Aucun CMS détecté</p>
                            <h4>Proxies du site</h4>
                            {cms.proxy.length > 0 && cms.proxy.map((proxy, index) => (
                                <p key={index}>{proxy}</p>
                            ))}
                        </>
                    ) : (
                        <>
                            <li>{cms.cms}</li>
                            <h4>Proxies du site</h4>
                            {cms.proxy.length > 0 && cms.proxy.map((proxy, index) => (
                                <p key={index}>{proxy}</p>
                            ))}

                            <h4>Version du CMS</h4>
                            <p>{cms.version}</p>

                            <h4>Plugins du CMS</h4>
                            {cms.plugins.map((plugin, index) => (
                                <p key={index}>{plugin}</p>
                            ))}

                        </>
                    )}
                </div>

            </div>

        )
    }


    const renderSSL = (title, ssl, icon) => {
        return (
            <div className="domain-section">
                <div className="domain-section-icon">
                    {icon}
                    <h4>{title}</h4>
                </div>
                <div className="domain-section-content">
                    <p>SSL Valide Détecté: {ssl.valid ? "Oui" : "Non"}</p>
                    <p>Date d'expiration: {ssl.expiration}</p>
                </div>
            </div>
        )
    }


    return (
        <div className="domain-results">
            <div className="domain-results-header">
                <h3>Résultats de {domain}</h3>
                <p>Durée de l'analyse : {duration.toFixed(2)} secondes</p>
            </div>

            <div className="security-section">
                <h4><Shield size={20} /> Analyses de sécurité</h4>
                {data["Headers_Analyzer"].issues.length === 0 ? 
                (
                    <p>Toutes les entêtes sont valides</p>
                )
                :
                (
                    <ul>
                    {data["Headers_Analyzer"].issues.map((header, index) => (
                        <li key={index}><AlertTriangle size={16} /> {header}</li>
                    ))}
                    </ul>
                )
            }
            </div>

            {renderSSL("Certificat SSL", data.SSL_Checker, <Lock size={20} />)}


            {renderSubDomains(data.Subdomain_Checker.subdomains)}


            {renderDNS("Zone DNS", data.DNS_Checker, <Globe size={20} />)}
            {renderWhois(data.WHOIS_Checker, <Database size={20} />)}

            {renderCMS("Détection de CMS", data.CMS_Detector, <Code size={20} />)}

            {/* {renderSection("Ports ouverts", data.Ports, <Server size={20} />)} */}
            {renderWaf("Web Application Firewall", data.WAF_Checker, <Server size={20} />)}
            {renderXSS("Faille(s) XSS", data.XSS_Checker, <CodeIcon size={20} />)}
            {renderSQLInjection("Faille(s) SQL Injection", data.SQL_Injection, <CodeIcon size={20} />)}
        </div>
    );
};

export default DomainResults;