import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Users, DollarSign, Bell, Settings, LogOut, Shield, Database, RefreshCw, Home } from 'lucide-react';
import { getLink } from '../utils/pointer';
import '../styles/Dashboard.scss';
import logo from '../img/Banner_transparent.png';
import Finances from './dash/Finances';
import { useNavigate } from 'react-router-dom';

// Import des composants de page
import Overview from './dash/Overview';
import UsersPage from './dash/Users';
import MaintenanceManager from './dash/MaintenanceManager';
import Security from './dash/Security';

const Dashboard = () => {
    const [currentPage, setCurrentPage] = useState('overview');
    const [isConnected, setIsConnected] = useState(false);
    const wsRef = useRef(null);
    const [users, setUsers] = useState([]);
    const [stats, setStats] = useState({});
    const [userActivity, setUserActivity] = useState([]);
    const [revenue, setRevenue] = useState([]);
    const [securityAlerts, setSecurityAlerts] = useState([]);
    const [lastUpdate, setLastUpdate] = useState(new Date());
    const [error, setError] = useState("")
    const navigate = useNavigate();
    const [maintenances, setMaintenances] = useState([]);
    const [products, setProducts] = useState([])
    const [sales, setSales] = useState([])
    const [promocodes, setPromocodes] = useState([])

    const connectWebSocket = useCallback(() => {
        const token = localStorage.getItem('db_token');
        if (!token) return navigate('/');
        const ws = new WebSocket(getLink() + '/wsadmin');
        ws.onopen = () => {
            setIsConnected(true);
            ws.send(JSON.stringify({ type: 'auth', token }));
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            switch (data.type) {
                case 'dashboardData':
                    console.info('📦 User datas: ', data.data.users)
                    setUsers(data.data.users);
                    setStats(data.data.stats);
                    setMaintenances(data.data.maintenances);
                    setProducts(data.data.products)
                    setSales(data.data.sales)
                    setPromocodes(data.data.promocodes)
                    setLastUpdate(new Date());
                    break;
                case 'authError':
                    navigate('/');
                    break;
                case 'ratelimit':
                    setError('Veuillez patienter..')
                    break;
                case 'authWelcome':
                    console.info("Received a welcome :", data)
                    break;
                case 'maintenanceAdded':
                case 'maintenanceUpdated':
                case 'maintenanceRemoved':
                    fetchData(); // Refresh data after maintenance changes
                    break;
                default:
                    console.warn('Type de message non reconnu:', data.type);
            }
        };

        ws.onclose = () => {
            console.log('WebSocket déconnecté');
            setIsConnected(false);
            setLastUpdate(new Date());
            setTimeout(connectWebSocket, 5000);
        };

        ws.onerror = (error) => {
            console.error('Erreur WebSocket:', error);
            setLastUpdate(new Date());
            ws.close();
        };

        wsRef.current = ws;

        return () => {
            if (ws) ws.close();
        };
    }, [navigate]);

    useEffect(() => {
        connectWebSocket();
        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [connectWebSocket]);

    const fetchData = useCallback(() => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify({ type: 'fetchData', token: localStorage.getItem("db_token") }));
        }
    }, []);

    const createMaintenance = useCallback((datas) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            console.info("Données qui vont etre envoyée: ", datas)
            wsRef.current.send(JSON.stringify({ type: 'addMaintenance', token: localStorage.getItem("db_token"), datas }));
        }
    }, []);

    const editMaintenance = useCallback((datas) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            console.info("[Edition] Données qui vont etre envoyée: ", datas)
            wsRef.current.send(JSON.stringify({ type: 'editMaintenance', token: localStorage.getItem("db_token"), datas }));
        }
    }, []);

    const removeMaintenance = useCallback((datas) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            console.info("Données qui vont etre envoyée: ", datas)
            wsRef.current.send(JSON.stringify({ type: 'removeMaintenance', token: localStorage.getItem("db_token"), datas }));
        }
    }, []);

    const updateUser = useCallback((updatedUser) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify({ type: 'updateUser', token: localStorage.getItem("db_token"), data: updatedUser }));
        }
    }, []);

    const deleteUser = useCallback((userId) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify({ type: 'deleteUser', token: localStorage.getItem("db_token"), data: userId }));
        }
    }, []);

    useEffect(() => {
        fetchData(); // Récupérer les données immédiatement au chargement
        const intervalId = setInterval(fetchData, 30000);
        return () => clearInterval(intervalId);
    }, [fetchData]);

    const handleLogout = () => {
        localStorage.removeItem('db_token');
        // Rediriger vers la page de connexion ou effectuer d'autres actions de déconnexion
        console.log("Déconnexion");
    };

    const handleGoHome = () => {
        wsRef.current.close();
        navigate('/')
    }

    const renderPage = () => {
        switch (currentPage) {
            case 'overview':
                return <Overview fetchStats={stats} userActivity={userActivity} revenue={revenue} securityAlerts={securityAlerts} users={users} />;
            case 'users':
                return <UsersPage users={users} loading={users.length === 0} updateUser={updateUser} deleteUser={deleteUser} />;
            case 'finances':
                return <Finances products={products} sales={sales} />;
            case 'security':
                return <Security />
            case 'maintenance':
                return <MaintenanceManager maintenances={maintenances} addNew={createMaintenance} edit={editMaintenance} remove={removeMaintenance} />;
            case 'settings':
                return <div>Page Paramètres</div>;
            default:
                return <Overview stats={stats} userActivity={userActivity} revenue={revenue} securityAlerts={securityAlerts} />;
        }
    };

    const getPageTitle = () => {
        switch (currentPage) {
            case 'overview': return "Vue d'ensemble";
            case 'users': return 'Gestion des utilisateurs';
            case 'finances': return 'Finances';
            case 'security': return 'Sécurité';
            case 'maintenance': return 'Maintenance';
            case 'settings': return 'Paramètres';
            default: return 'Tableau de bord';
        }
    };

    return (
        isConnected &&
        <div className="admin-dashboard">
            <aside className="sidebar">
                <div className="logo">
                    <img src={logo} onClick={handleGoHome} alt="Logo" />
                </div>
                <nav>
                    <button onClick={() => setCurrentPage('overview')} className={currentPage === 'overview' ? 'active' : ''}>
                        <Home size={20} /> Vue d'ensemble
                    </button>
                    <button onClick={() => setCurrentPage('users')} className={currentPage === 'users' ? 'active' : ''}>
                        <Users size={20} /> Utilisateurs
                    </button>
                    <button onClick={() => setCurrentPage('finances')} className={currentPage === 'finance' ? 'active' : ''}>
                        <DollarSign size={20} /> Finances
                    </button>
                    <button onClick={() => setCurrentPage('security')} className={currentPage === 'security' ? 'active' : ''}>
                        <Shield size={20} /> Sécurité
                    </button>
                    <button onClick={() => setCurrentPage('maintenance')} className={currentPage === 'maintenance' ? 'active' : ''}>
                        <Database size={20} /> Maintenance
                    </button>
                    <button onClick={() => setCurrentPage('settings')} className={currentPage === 'settings' ? 'active' : ''}>
                        <Settings size={20} /> Paramètres
                    </button>
                </nav>
                <button className="logout-btn" onClick={handleLogout}><LogOut size={20} /> Déconnexion</button>
            </aside>
            <main className="dashboard-main">
                <header className="dashboard-header">
                    <h1>{getPageTitle()}</h1>
                    <div className="header-actions">
                        <button onClick={fetchData} disabled={!isConnected} className="refresh-btn">
                            <span>Dernière mise à jour<br />{lastUpdate.toLocaleString('fr-FR', {
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            })}</span>
                            <RefreshCw size={20} />
                        </button>
                        <Bell size={24} />
                        <img src="https://i.pravatar.cc/40" alt="Admin" className="admin-avatar" />
                    </div>
                </header>
                <div className="dashboard-content">
                    {renderPage()}
                </div>
            </main>
        </div>
    );
};

export default Dashboard;