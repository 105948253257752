import React, { useEffect, useState } from 'react';
import Seo from '../components/Seo'
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { getLink } from '../utils/pointer';
import '../styles/BlogPost.scss';

const BlogPost = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        fetch(getLink() + `/blog/${id}`)
            .then(async response => {
                const resp = await response.json();
                setPost(resp);
            })
            .catch(err => console.error(err));
    }, [id]);

    if (!post) {
        return <div className="loading-container">
            <div className="loading-spinner"></div>
            <p>Chargement de l'article...</p>
        </div>;
    }

    const renderContent = (content) => {
        return content.map((item, index) => {
            switch (item.type) {
                case 'header':
                    return <h2 key={index} className="content-header">{item.content}</h2>;
                case 'subheader':
                    return <h3 key={index} className="content-subheader">{item.content}</h3>;
                case 'paragraph':
                    return <p key={index} className="content-paragraph">{item.content}</p>;
                case 'quote':
                    return (
                        <blockquote key={index} className="content-quote">
                            <p>{item.content}</p>
                            {item.author}
                        </blockquote>
                    );
                case 'list':
                    return (
                        <ul key={index} className="content-list">
                            {item.content.split('<nl>').map((listItem, i) => (
                                <li key={i}>{listItem}</li>
                            ))}
                        </ul>
                    );
                case 'code':
                    return (
                        <div key={index} className="code-container">
                            {item.language && <span className="code-language">{item.language}</span>}
                            <pre><code>{item.content}</code></pre>
                        </div>
                    );
                case 'image':
                    return (
                        console.info("ITEM", item),
                        <figure key={index} className="content-image">
                            <img src={item.content} alt={item.caption || 'Image de l\'article'} />

                            {item.caption && <figcaption>{item.caption}</figcaption>}
                        </figure>
                    );
                case 'video':
                    return (
                        <div key={index} className="content-video">
                            <video
                                controls
                                poster={item.thumbnail}
                                className="video-player"
                            >
                                <source src={item.content} type={item.videoType || 'video/mp4'} />
                                Votre navigateur ne supporte pas la lecture de vidéos.
                            </video>
                            {item.caption && <p className="video-caption">{item.caption}</p>}
                        </div>
                    );
                case 'download':
                    return (
                        <div key={index} className="download-section">
                            <a href={item.url} download className="download-button">
                                <i className="download-icon"></i>
                                {item.label || 'Télécharger le fichier'}
                            </a>
                            {item.fileSize && <span className="file-size">{item.fileSize}</span>}
                        </div>
                    );
                default:
                    return null;
            }
        });
    }

    return (
        <>
            <Seo
                title={"Ozzzint.fr - " + post.title}
                description={post.desc}
                canonicalUrl={"https://ozzzint.fr/blog/" + post._id}
                keywords="osint, cybersecurity, firewall, lookup"
            />
            <article className="blogpost">
                <div className='container'>
                    <header className="post-header">
                        <h1 className='title'>{post.title}</h1>
                        <p className='desc'>{post.desc}</p>

                        <div className='bp-header'>
                            <div className='category-container'>
                                {Array.isArray(post.category)
                                    ? post.category.map((cat, index) =>
                                        <span key={index} className='category'>{cat}</span>
                                    )
                                    : <span className='category'>{post.category}</span>
                                }
                            </div>
                            <div className='post-meta'>
                                <p className='author'>Par <span>{post.author}</span></p>
                                <p className='date'>Le <span>{new Date(post.createdAt).toLocaleDateString('fr-FR', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</span></p>
                            </div>
                        </div>
                    </header>

                    {post.coverImage && (
                        <div className='cover-image'>
                            <img src={post.coverImage} alt={post.title} />
                        </div>
                    )}

                    <div className='content'>
                        {renderContent(post.content)}
                    </div>
                </div>
            </article>
        </>
    );
};

export default BlogPost;