import React, { useState, useEffect } from 'react';
import { Clock, AlertTriangle, CheckCircle, XCircle, Plus, Edit, Trash2, Calendar } from 'lucide-react';
import { FaToolbox } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../../styles/MaintenanceManager.scss'

const MaintenanceManager = ({ maintenances, addNew, remove, edit }) => {
    const [selectedMaintenance, setSelectedMaintenance] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [newMaintenance, setNewMaintenance] = useState({
        title: '',
        description: '',
        startTime: new Date(),
        estimatedEndTime: new Date(),
        isActive: false,
        progress: 0,
        logs: []
    });
    const [newLog, setNewLog] = useState({ type: 'completed', title: '', description: '' });


    const handleCreateMaintenance = () => {
        addNew(newMaintenance);
        setIsCreating(false);
        setNewMaintenance({
            title: '',
            description: '',
            startTime: new Date(),
            estimatedEndTime: new Date(),
            isActive: false,
            progress: 0,
            logs: []
        });
    };

    const handleUpdateMaintenance = (updatedMaintenance) => {
        edit(updatedMaintenance);
        setSelectedMaintenance(null);
    };

    const handleDeleteMaintenance = (id) => {
        remove(id);
        setSelectedMaintenance(null);
    };


    const handleAddLog = (maintenanceId, log) => {
        console.info("trying to find a log: ", log, maintenanceId)
        const updatedMaintenance = maintenances.find(m => m._id === maintenanceId);
        if (updatedMaintenance) {
            updatedMaintenance.logs.push({ ...log, timestamp: new Date().toISOString() });
            console.info("Found a log: ", log, maintenanceId)
            edit(updatedMaintenance);
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const getLogIcon = (type) => {
        switch (type) {
            case 'completed':
                return <CheckCircle size={18} className="log-icon completed" />;
            case 'in_progress':
                return <Clock size={18} className="log-icon in-progress" />;
            case 'warning':
                return <AlertTriangle size={18} className="log-icon warning" />;
            default:
                return <XCircle size={18} className="log-icon" />;
        }
    };

    return (
        <div className="maintenance-manager">
            <h2><FaToolbox size={24} /> Gestion de la maintenance</h2>

            <div className="maintenance-list">
                <h3>Maintenances</h3>
                <button className="create-btn" onClick={() => setIsCreating(true)}>
                    <Plus size={18} /> Nouvelle maintenance
                </button>
                {maintenances.map(maintenance => (
                    <div key={maintenance._id} className="maintenance-item">
                        <h4>{maintenance.title}</h4>
                        <p>{maintenance.description}</p>
                        <p><strong>Statut:</strong> {maintenance.isActive ? 'En cours' : 'Planifiée'}</p>
                        <p><strong>Début:</strong> {formatDate(maintenance.startTime)}</p>
                        <p><strong>Fin estimée:</strong> {formatDate(maintenance.estimatedEndTime)}</p>
                        <div className="maintenance-actions">
                            <button onClick={() => setSelectedMaintenance(maintenance)}><Edit size={18} /> Éditer</button>
                            <button onClick={() => handleDeleteMaintenance(maintenance._id)}><Trash2 size={18} /> Supprimer</button>
                        </div>
                    </div>
                ))}
            </div>

            {isCreating && (
                <div className="maintenance-form">
                    <h3>Créer une nouvelle maintenance</h3>
                    <input
                        type="text"
                        placeholder="Titre"
                        value={newMaintenance.title}
                        onChange={(e) => setNewMaintenance({ ...newMaintenance, title: e.target.value })}
                    />
                    <textarea
                        placeholder="Description"
                        value={newMaintenance.description}
                        onChange={(e) => setNewMaintenance({ ...newMaintenance, description: e.target.value })}
                    />
                    <div className="date-picker-container">
                        <label>Date de début:</label>
                        <DatePicker
                            selected={newMaintenance.startTime}
                            onChange={(date) => setNewMaintenance({ ...newMaintenance, startTime: date })}
                            showTimeSelect
                            dateFormat="Pp"
                        />
                    </div>
                    <div className="date-picker-container">
                        <label>Date de fin estimée:</label>
                        <DatePicker
                            selected={newMaintenance.estimatedEndTime}
                            onChange={(date) => setNewMaintenance({ ...newMaintenance, estimatedEndTime: date })}
                            showTimeSelect
                            dateFormat="Pp"
                        />
                    </div>
                    <button onClick={handleCreateMaintenance}>Créer</button>
                    <button onClick={() => setIsCreating(false)}>Annuler</button>
                </div>
            )}

            {selectedMaintenance && (
                <div className="maintenance-details">
                    <h3>Détails de la maintenance</h3>
                    <input
                        type="text"
                        value={selectedMaintenance.title}
                        onChange={(e) => setSelectedMaintenance({ ...selectedMaintenance, title: e.target.value })}
                    />
                    <textarea
                        value={selectedMaintenance.description}
                        onChange={(e) => setSelectedMaintenance({ ...selectedMaintenance, description: e.target.value })}
                    />
                    <div className="date-picker-container">
                        <label>Date de début:</label>
                        <DatePicker
                            selected={new Date(selectedMaintenance.startTime)}
                            onChange={(date) => setSelectedMaintenance({ ...selectedMaintenance, startTime: date.toISOString() })}
                            showTimeSelect
                            dateFormat="Pp"
                        />
                    </div>
                    <div className="date-picker-container">
                        <label>Date de fin estimée:</label>
                        <DatePicker
                            selected={new Date(selectedMaintenance.estimatedEndTime)}
                            onChange={(date) => setSelectedMaintenance({ ...selectedMaintenance, estimatedEndTime: date.toISOString() })}
                            showTimeSelect
                            dateFormat="Pp"
                        />
                    </div>
                    <div className="progress-container">
                        <label>Progression: {selectedMaintenance.progress}%</label>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={selectedMaintenance.progress}
                            onChange={(e) => setSelectedMaintenance({ ...selectedMaintenance, progress: parseInt(e.target.value) })}
                        />
                    </div>
                    <div className="status-toggle">
                        <label>
                            <input
                                type="checkbox"
                                checked={selectedMaintenance.isActive}
                                onChange={(e) => setSelectedMaintenance({ ...selectedMaintenance, isActive: e.target.checked })}
                            />
                            Maintenance active
                        </label>
                    </div>
                    <button onClick={() => handleUpdateMaintenance(selectedMaintenance)}>Mettre à jour</button>
                    <button onClick={() => setSelectedMaintenance(null)}>Fermer</button>

                    <div className="maintenance-logs">
                        <h4>Logs</h4>
                        <ul>
                            {selectedMaintenance.logs.map((log, index) => (
                                <li key={index} className={`log-item ${log.type}`}>
                                    {getLogIcon(log.type)}
                                    <div className="log-content">
                                        <h5>{log.title}</h5>
                                        <p>{log.description}</p>
                                        <span className="log-timestamp">{formatDate(log.timestamp)}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="add-log-form">
                            <h5>Ajouter un log</h5>
                            <select onChange={(e) => setNewLog({ ...newLog, type: e.target.value })}>
                                <option value="completed">Terminé</option>
                                <option value="in_progress">En cours</option>
                                <option value="warning">Avertissement</option>
                            </select>
                            <input
                                type="text"
                                placeholder="Titre du log"
                                onChange={(e) => setNewLog({ ...newLog, title: e.target.value })}
                            />
                            <textarea
                                placeholder="Description du log"
                                onChange={(e) => setNewLog({ ...newLog, description: e.target.value })}
                            />
                            <button onClick={() => {
                                handleAddLog(selectedMaintenance._id, newLog);
                                setNewLog({ type: 'completed', title: '', description: '' });
                            }}>Ajouter le log</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MaintenanceManager;