import React, { useState, useEffect } from 'react';
import '../styles/Blog.scss';
import Seo from '../components/Seo';
import { useUser } from '../contexts/UserContext';
import { ChevronDown, X, Search, Bug, Plus, Pen, ChartArea, House } from 'lucide-react';
import { getLink } from '../utils/pointer';
import BlogModal from '../components/BlogModal';

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [filter, setFilter] = useState({ category: 'all', sortBy: 'date' });
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { isStaff } = useUser();
  const [selectedTab, setSelectedTab] = useState(null);
  const formData = {

  };

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch(getLink() + '/blog', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('db_token') || null,
        },
    });
      const data = await response.json();
      setBlogPosts(data);
    };

    fetchPosts();
  }, []);

  const filteredPosts = blogPosts
    .filter(post =>
      (filter.category === 'all' || post.category === filter.category) &&
      (post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.desc.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => {
      if (filter.sortBy === 'date') {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else {
        return a.title.localeCompare(b.title);
      }
    });

  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleReadMore = (post) => {
    window.open('/blog/' + post._id, '_blank');
  };

  const handleCloseArticle = () => {
    setSelectedPost(null);
    document.body.style.overflow = 'auto';
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const handleTabClick = (selected) => {
    setSelectedTab(selected)
  }

  const handleInputChange = () => {

  }

  const renderCategory = (category) => {
    switch (category) {
      case 'news':
        return 'Nouveautés'
      case 'guides':
        return 'Guides'
      case 'tips':
        return 'Conseils'
      default:
        return 'Autres'
    }
  }

  return (
    <>
      <Seo
        title="Blog Cybersécurité et OSINT | Ozzzint.fr"
        description="Explorez nos articles experts sur l'OSINT, la cybersécurité et les techniques d'investigation numérique. Restez informé des dernières tendances et outils."
        canonicalUrl="https://ozzzint.fr/blog"
        keywords="blog OSINT, articles cybersécurité, investigation numérique, veille sécurité"
        author="Ozzzint"
      />
      <div className="blog">
        {/* <Navbar /> */}
        <div className="container">
          <h1 className="title"><Bug size={48} /> Blog & Insights</h1>

          <section className="blog-intro">
            <p className="lead-text">
              Explorez l'avenir de l'OSINT et de la cybersécurité à travers nos analyses expertes.
            </p>
          </section>

          <section className={`blog-filters ${isFilterOpen ? 'open' : ''}`}>
            <div className="filter-toggle" onClick={toggleFilter}>
              Filtrer et trier <ChevronDown size={20} />
            </div>
            <div className="filter-options">
              <select name="category" onChange={handleFilterChange} value={filter.category}>
                <option value="all">Toutes les catégories</option>
                <option value="news">Nouveautés</option>
                <option value="guides">Guides</option>
                <option value="tips">Conseils</option>
                <option value="other">Autres</option>
              </select>
              <select name="sortBy" onChange={handleFilterChange} value={filter.sortBy}>
                <option value="date">Les plus récents</option>
                <option value="title">Ordre alphabétique</option>
              </select>
            </div>
            <div className="search-bar">
              <Search size={20} />
              <input
                type="text"
                placeholder="Rechercher un article"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </section>

          <section className="blog-grid">
            {filteredPosts.map((post) => (
              <article key={post._id} className="blog-post" onClick={() => handleReadMore(post)}>
                <div className="post-image" style={{ backgroundImage: `url(${post.image})` }} />
                <div className="post-content">
                  <h2 className="post-title">{post.title}</h2>
                  <p className="post-desc">{post.desc}</p>
                  <div className="post-meta">
                    <span className="post-category">{
                      renderCategory(post.category)
                    }</span>
                    <span className="post-date">{new Date(post.createdAt).toLocaleDateString()}</span>
                  </div>
                </div>
              </article>
            ))}
          </section>

          {selectedPost && (
            <div className="article-modal">
              <div className="modal-content">
                <button className="close-button" onClick={handleCloseArticle}>
                  <X size={24} />
                </button>
                <h2>{selectedPost.title}</h2>
                <div className="article-meta">
                  <span className="article-date">Le {new Date(selectedPost.date).toDateString("fr-FR", { hour: '2-digit', minute: '2-digit' })} par
                    <span className="article-author">{selectedPost.author}</span>
                  </span>
                  ▪
                  <span className="article-category">{selectedPost.category}</span>
                </div>
                <div className="article-image" style={{ backgroundImage: `url(${selectedPost.image})` }} />
                {selectedPost.content.map((item, index) => (
                  <div key={index}>
                    {item.type === 'header' && <h3 className='article-header'>{item.content}</h3>}
                    {item.type === 'paragraph' && <p className='article-paragraph'>{item.content}</p>}
                    {item.type === 'spacer' && <div className="spacer" />}
                    {item.type === 'quote' && <blockquote className='article-quote'>"{item.content}"</blockquote>}
                    {item.type === 'list' && <ul className='article-list'>{item.content.map((item, index) => <li key={index}>{item}</li>)}</ul>}
                    {item.type === 'code' && <pre className='article-code'>{item.content}</pre>}
                  </div>
                ))}
              </div>
            </div>
          )}

          {isStaff && (
            <div className='admin-create'>
              <button onClick={toggleModal}><Plus /></button>
            </div>
          )}

          <section className="blog-cta">
            <h2 className="section-title">Restez à la pointe de l'innovation</h2>
            <p className="section-text">
              Abonnez-vous pour recevoir nos dernières analyses et découvertes directement dans votre boîte mail.
            </p>
            <button type="submit" className="cta-button">Newsletter bientôt disponible...</button>
          </section>
        </div>
        {showModal && <BlogModal isStaff={isStaff} showModal={showModal} toggleModal={toggleModal} />}
      </div>
    </>
  );
};

export default Blog;