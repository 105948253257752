// App.js
import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import * as Pages from './pages';
import { getLink } from './utils/pointer'
import './styles/App.scss';

// Définition des routes avec leurs configurations
const ROUTES = [
  { path: '/login', Component: Pages.Login },
  { path: '/register', Component: Pages.Register },
  { path: '/', Component: Pages.Search },
  { path: '/maintenance', Component: Pages.Maintenance },
  { path: '/legals', Component: Pages.Legals },
  { path: '/our-team', Component: Pages.OurTeam },
  { path: '/our-missions', Component: Pages.OurMissions, additionalComponent: Pages.OurTeam },
  { path: '/blog', Component: Pages.Blog },
  { path: '/blog/:id', Component: Pages.BlogPost },
  { path: '/params', Component: Pages.UserParams, props: { selectedPage: 'account' } },
  { path: '/api-pricing-docs', Component: Pages.ApiPricingDocs },
  { path: '/manager', Component: Pages.Dashboard, hideNavFooter: true },
  { path: '/formations', Component: Pages.Formations},
  { path: '*', Component: Pages.NotFound }
];

const PageWrapper = ({ Component, additionalComponent: Additional, hideNavFooter, hasMaintenance, ...props }) => {
  const location = useLocation();
  if (hasMaintenance && location.pathname !== '/manager') {
    return <Pages.Maintenance />
  }

  return (
    <>
      {!hideNavFooter && <Navbar />}
      <Component {...props} />
      {Additional && <Additional />}
      {!hideNavFooter && <Footer />}
    </>
  );
};

const AppRoutes = ({ hasMaintenance }) => (
  <Routes>
    {ROUTES.map(({ path, Component, ...routeProps }) => (
      <Route
        key={path}
        path={path}
        element={<PageWrapper Component={Component} {...routeProps} hasMaintenance={hasMaintenance} />}
      />
    ))}
  </Routes>
);

const App = () => {
  const [hasMaintenance, setHasMaintenance] = useState(false);

  const fetchMaintenanceStatus = async () => {
    try {
      const response = await fetch(getLink() + '/maintenance-status');
      if (!response.ok) throw new Error('Erreur réseau');

      const data = await response.json();
      setHasMaintenance(data.isActive)
    } catch (error) {
      setHasMaintenance(false)
      return;
    }
  };



  useEffect(() => {
    const POLLING_INTERVAL = 60000; // 5 secondes entre chaque requête
    fetchMaintenanceStatus();
    const interval = setInterval(fetchMaintenanceStatus, POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  return (
    <HelmetProvider>
      <div className="app">
        <AppRoutes hasMaintenance={hasMaintenance} />
      </div>
    </HelmetProvider>
  );
};

export default App;