import React, { useState, useEffect } from 'react';
import { DollarSign, ShoppingCart, FileText, Tag, Users, Percent, Activity, Package, CreditCard, Filter, Search, Plus, Minus, Edit, Trash2, Download } from 'lucide-react';
import '../../styles/Finances.scss';

const Finances = ({ sales, products, customers }) => {
    const [filteredSales, setFilteredSales] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedSale, setSelectedSale] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [activeTab, setActiveTab] = useState('sales');
    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState('all');
    const [newProduct, setNewProduct] = useState({ name: '', price: 0, stock: 0 });
    const [newDiscount, setNewDiscount] = useState({ code: '', percentage: 0 });

    useEffect(() => {
        setFilteredSales(sales);
        setFilteredProducts(products);
    }, [sales, products]);

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        filterItems(term, filterStatus);
    };

    const handleFilterChange = (e) => {
        const status = e.target.value;
        setFilterStatus(status);
        filterItems(searchTerm, status);
    };

    const filterItems = (term, status) => {
        if (activeTab === 'sales') {
            const filtered = sales.filter(sale =>
                (sale.customer.name.toLowerCase().includes(term) || sale.id.toLowerCase().includes(term)) &&
                (status === 'all' || sale.status === status)
            );
            setFilteredSales(filtered);
        } else {
            const filtered = products.filter(product =>
                product.name.toLowerCase().includes(term)
            );
            setFilteredProducts(filtered);
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setSearchTerm('');
        setFilterStatus('all');
        if (tab === 'sales') {
            setFilteredSales(sales);
        } else {
            setFilteredProducts(products);
        }
    };

    const handleSaleSelect = (sale) => {
        setSelectedSale(sale);
    };

    const handleProductSelect = (product) => {
        setSelectedProduct(product);
    };

    const generateInvoice = (sale) => {
        // Logic to generate and download invoice
        console.log(`Generating invoice for sale ${sale.id}`);
    };

    const updateSaleStatus = (saleId, newStatus) => {
        // Logic to update sale status
        console.log(`Updating sale ${saleId} status to ${newStatus}`);
    };

    const addNewProduct = () => {
        // Logic to add new product
        console.log('Adding new product:', newProduct);
        setNewProduct({ name: '', price: 0, stock: 0 });
    };

    const updateProduct = (productId, updatedDetails) => {
        // Logic to update product
        console.log(`Updating product ${productId}:`, updatedDetails);
    };

    const deleteProduct = (productId) => {
        // Logic to delete product
        console.log(`Deleting product ${productId}`);
    };

    const addDiscount = () => {
        // Logic to add new discount
        console.log('Adding new discount:', newDiscount);
        setNewDiscount({ code: '', percentage: 0 });
    };

    const handleReferral = (referrerId, referredId) => {
        // Logic to handle referral
        console.log(`Referral: ${referrerId} referred ${referredId}`);
    };

    const renderSalesList = () => (
        <div className="sales-list">
            <h2>Ventes</h2>
            {filteredSales.map(sale => (
                <div key={sale.id} className="sale-item" onClick={() => handleSaleSelect(sale)}>
                    <span className="sale-id">{sale.id}</span>
                    <span className="sale-customer">{sale.customer.name}</span>
                    <span className="sale-amount">{sale.total}€</span>
                    <span className={`sale-status ${sale.status}`}>{sale.status}</span>
                </div>
            ))}
        </div>
    );

    const renderProductsList = () => (
        <div className="products-list">
            <h2>Produits</h2>
            {filteredProducts.map(product => (
                <div key={product.id} className="product-item" onClick={() => handleProductSelect(product)}>
                    <span className="product-name">{product.name}</span>
                    <span className="product-price">{product.price}€</span>
                    <span className="product-stock">Stock: {product.stock}</span>
                </div>
            ))}
        </div>
    );

    const renderSaleDetails = () => {
        if (!selectedSale) return null;
        return (
            <div className="sale-details">
                <h3>Détails de la vente {selectedSale.id}</h3>
                <p>Client: {selectedSale.customer.name}</p>
                <p>Date: {selectedSale.date}</p>
                <p>Total: {selectedSale.total}€</p>
                <p>Statut:
                    <select
                        value={selectedSale.status}
                        onChange={(e) => updateSaleStatus(selectedSale.id, e.target.value)}
                    >
                        <option value="pending">En attente</option>
                        <option value="processing">En cours</option>
                        <option value="shipped">Expédié</option>
                        <option value="delivered">Livré</option>
                        <option value="cancelled">Annulé</option>
                    </select>
                </p>
                <button onClick={() => generateInvoice(selectedSale)}>
                    <FileText size={16} />
                    Générer la facture
                </button>
            </div>
        );
    };

    const renderProductDetails = () => {
        if (!selectedProduct) return null;
        return (
            <div className="product-details">
                <h3>Détails du produit</h3>
                <input
                    type="text"
                    value={selectedProduct.name}
                    onChange={(e) => updateProduct(selectedProduct.id, { ...selectedProduct, name: e.target.value })}
                />
                <input
                    type="number"
                    value={selectedProduct.price}
                    onChange={(e) => updateProduct(selectedProduct.id, { ...selectedProduct, price: parseFloat(e.target.value) })}
                />
                <input
                    type="number"
                    value={selectedProduct.stock}
                    onChange={(e) => updateProduct(selectedProduct.id, { ...selectedProduct, stock: parseInt(e.target.value) })}
                />
                <button onClick={() => deleteProduct(selectedProduct.id)}>
                    <Trash2 size={16} />
                    Supprimer
                </button>
            </div>
        );
    };

    return (
        <div className="finance-management">
            <div className="finance-header">
                <h1>Gestion des Finances</h1>
                <div className="tab-navigation">
                    <button
                        className={activeTab === 'sales' ? 'active' : ''}
                        onClick={() => handleTabChange('sales')}
                    >
                        <ShoppingCart size={16} />
                        Ventes
                    </button>
                    <button
                        className={activeTab === 'products' ? 'active' : ''}
                        onClick={() => handleTabChange('products')}
                    >
                        <Package size={16} />
                        Produits
                    </button>
                </div>
            </div>
            <div className="finance-filters">
                <div className="search-bar">
                    <Search size={16} />
                    <input
                        type="text"
                        placeholder="Rechercher..."
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                </div>
                {activeTab === 'sales' && (
                    <select value={filterStatus} onChange={handleFilterChange}>
                        <option value="all">Tous les statuts</option>
                        <option value="pending">En attente</option>
                        <option value="processing">En cours</option>
                        <option value="shipped">Expédié</option>
                        <option value="delivered">Livré</option>
                        <option value="cancelled">Annulé</option>
                    </select>
                )}
            </div>
            <div className="finance-content">
                <div className="finance-list">
                    {activeTab === 'sales' ? renderSalesList() : renderProductsList()}
                </div>
                <div className="finance-details">
                    {activeTab === 'sales' ? renderSaleDetails() : renderProductDetails()}
                </div>
            </div>
            {activeTab === 'products' && (
                <div className="new-product-form">
                    <h3>Ajouter un nouveau produit</h3>
                    <input
                        type="text"
                        placeholder="Nom du produit"
                        value={newProduct.name}
                        onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
                    />
                    <input
                        type="number"
                        placeholder="Prix"
                        value={newProduct.price}
                        onChange={(e) => setNewProduct({ ...newProduct, price: parseFloat(e.target.value) })}
                    />
                    <input
                        type="number"
                        placeholder="Stock"
                        value={newProduct.stock}
                        onChange={(e) => setNewProduct({ ...newProduct, stock: parseInt(e.target.value) })}
                    />
                    <button onClick={addNewProduct}>
                        <Plus size={16} />
                        Ajouter le produit
                    </button>
                </div>
            )}
            <div className="discount-section">
                <h3>Gérer les réductions</h3>
                <input
                    type="text"
                    placeholder="Code de réduction"
                    value={newDiscount.code}
                    onChange={(e) => setNewDiscount({ ...newDiscount, code: e.target.value })}
                />
                <input
                    type="number"
                    placeholder="Pourcentage de réduction"
                    value={newDiscount.percentage}
                    onChange={(e) => setNewDiscount({ ...newDiscount, percentage: parseInt(e.target.value) })}
                />
                <button onClick={addDiscount}>
                    <Percent size={16} />
                    Ajouter la réduction
                </button>
            </div>
            <div className="referral-section">
                <h3>Système de Parrainage</h3>
                <p>Entrez l'ID du parrain et du filleul pour appliquer le parrainage :</p>
                <input type="text" placeholder="ID du parrain" id="referrerId" />
                <input type="text" placeholder="ID du filleul" id="referredId" />
                <button onClick={() => handleReferral(
                    document.getElementById('referrerId').value,
                    document.getElementById('referredId').value
                )}>
                    <Users size={16} />
                    Appliquer le parrainage
                </button>
            </div>
        </div>
    );
};

export default Finances;