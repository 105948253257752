import React, { useState, useEffect } from 'react';
import { FiClock, FiCheckCircle, FiAlertCircle, FiRefreshCw } from 'react-icons/fi';
import logo from '../img/Banner_transparent.png';
import { useNavigate } from 'react-router-dom';
import { getLink } from '../utils/pointer'
import '../styles/Maintenance.scss';

const POLLING_INTERVAL = 5000; // 5 secondes entre chaque requête

const Maintenance = () => {
  const [maintenanceData, setMaintenanceData] = useState({
    status: [],
    progress: 0,
    isLoading: true,
    error: null
  });
  const [lastRefresh, setLastRefresh] = useState(0);
  const [waitPlease, setWaitPlease] = useState("");
  const navigate = useNavigate();

  const fetchMaintenanceStatus = async () => {

    // Vérifier si l'utilisateur peut cliquer à nouveau
    if (Date.now() - lastRefresh < 5000) {
      setWaitPlease("Veuillez patienter avant de rafraîchir à nouveau.");
      return;
    }

    try {
      const response = await fetch(getLink() + '/maintenance-status');
      if (!response.ok) throw new Error('Erreur réseau');

      const data = await response.json();

      if (!data.isActive) {
        return window.location.reload();
      }

      setMaintenanceData(prevData => ({
        ...prevData,
        status: data.status || [],
        progress: data.progress || 0,
        isLoading: false,
        error: null
      }));

      setLastRefresh(Date.now());
      setWaitPlease('')
    } catch (error) {
      setMaintenanceData(prevData => ({
        ...prevData,
        isLoading: false,
        error: "Impossible de récupérer le statut de maintenance"
      }));
    }
  };

  useEffect(() => {
    document.title = "OzZzint.fr 📦 Maintenance";

    fetchMaintenanceStatus();
    const interval = setInterval(fetchMaintenanceStatus, POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  const getStatusIcon = (status) => {
    switch (status) {
      case 'completed': return <FiCheckCircle className="status-icon complete" />;
      case 'in_progress': return <FiClock className="status-icon in-progress" />;
      case 'warning': return <FiAlertCircle className="status-icon warning" />;
      default: return <FiClock className="status-icon in-progress" />;
    }
  };

  return (
    <div className="maintenance-page">
      <div className="maintenance-content">
        <img src={logo} alt="OzZzint Logo" className="logo" />

        <div className="status-section">
          <div className="status-header">
            <h2>État de la maintenance</h2>
            <button
              onClick={fetchMaintenanceStatus}
              className={`refresh-button ${maintenanceData.isLoading ? 'rotating' : ''}`}
              disabled={maintenanceData.isLoading || waitPlease !== ''}
            >
              <FiRefreshCw />
            </button>
          </div>

          <div className="progress-container">
            <div className="progress-bar">
              <div
                className="progress"
                style={{ width: `${maintenanceData.progress}%` }}
              />
            </div>
            <p className="progress-text">{maintenanceData.progress}% complété</p>
          </div>

          <div className="status-container">
            {maintenanceData.error ? (
              <div className="error-message">{maintenanceData.error}</div>
            ) : maintenanceData.isLoading ? (
              <div className="loading-message">Chargement des statuts...</div>
            ) : maintenanceData.status.length === 0 ? (
              <div className="empty-message">Aucun statut disponible</div>
            ) : (
              maintenanceData.status.map((item, index) => (
                <div key={index} className="status-item">
                  {getStatusIcon(item.type)}
                  <div className="status-info">
                    <div className="status-header">
                      <span className="status-title">{item.title}</span>
                      <span className="status-date">
                        {new Date(item.timestamp).toLocaleTimeString()}
                      </span>
                    </div>
                    <div className="status-description">
                      {item.description.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          {index < item.description.split('\n').length - 1 && <br />}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <p className="maintenance-message">
          Nous travaillons à l'amélioration de notre site. Merci de votre patience.
        </p>

        <p className="maintenance-footer">www.ozzzint.fr</p>
      </div>
    </div>
  );
};

export default Maintenance;