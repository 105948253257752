import React, { useState, useEffect } from 'react';
import {
    Users, DollarSign, Activity, AlertTriangle, Bell, Shield,
    TrendingUp, PieChart, Server, Zap,
    User
} from 'lucide-react';
import '../../styles/Dashboard.scss';

const Overview = ({ users, fetchStats }) => {
    console.info("Fetched stats :", fetchStats)
    const [stats, setStats] = useState({
        totalUsers: fetchStats.totalUsers || 0,
        activeUsers: fetchStats.activeUsers || 0,
        totalRevenue: fetchStats.totalRevenue || 0,
        systemStatus: fetchStats.systemStatus || 'Down',
        cpuUsage: fetchStats.cpuUsage ||  "0%",
        memoryUsage: fetchStats.memoryUsage || "0%",
        diskUsage: fetchStats.diskUsage || "0%",
    });
    const [userActivityData, setUserActivityData] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [securityAlerts, setSecurityAlerts] = useState([]);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        // Simuler le chargement des données
        const fetchData = () => {
            setUserActivityData([
                { date: '2024-01', activeUsers: 3000 },
                { date: '2024-02', activeUsers: 3200 },
                { date: '2024-03', activeUsers: 3500 },
                { date: '2024-04', activeUsers: 3800 },
            ]);

            setRevenueData([
                { date: '2024-01', revenue: 200000 },
                { date: '2024-02', revenue: 220000 },
                { date: '2024-03', revenue: 240000 },
                { date: '2024-04', revenue: 250000 },
            ]);

            setSecurityAlerts([
                { message: "Tentative de connexion suspecte détectée", time: "Il y a 5 minutes" },
                { message: "Mise à jour de sécurité disponible", time: "Il y a 2 heures" },
            ]);

            setNotifications([
                { message: "Nouveau rapport mensuel disponible", time: "Il y a 10 minutes" },
                { message: "5 nouveaux utilisateurs inscrits", time: "Il y a 1 heure" },
            ]);
        };

        fetchData();
        const interval = setInterval(fetchData, 60000); // Actualiser toutes les minutes
        return () => clearInterval(interval);
    }, []);

    const StatCard = ({ icon, title, value, color }) => (
        <div className="stat-card" style={{ borderColor: color }}>
            {icon}
            <div className="stat-info">
                <h3>{title}</h3>
                <p>{value}</p>
            </div>
        </div>
    );

    const SecurityAlert = ({ alert }) => (
        <div className="security-alert">
            <AlertTriangle size={18} color="#ff9800" />
            <div className="alert-content">
                <p>{alert.message}</p>
                <span>{alert.time}</span>
            </div>
            <button className="resolve-btn">Résoudre</button>
        </div>
    );

    return (
        <div className="overview-page">
            <section className="stats-section">
                <StatCard icon={<Users size={24} />} title="Utilisateurs totaux" value={stats.totalUsers} color="#4CAF50" />
                <StatCard icon={<User size={24} />} title="Utilisateurs actifs" value={stats.activeUsers} color="#2196F3" />
                <StatCard icon={<DollarSign size={24} />} title="Revenu total" value={`${stats.totalRevenue.toLocaleString()} €`} color="#FFC107" />
                <StatCard icon={<Activity size={24} />} title="Statut système" value={stats.systemStatus} color="#9C27B0" />
            </section>

            <section className="system-health-section">
                <h2><Server size={20} /> Santé du système</h2>
                <div className="health-stats">
                    <div className="health-stat">
                        <h3>CPU</h3>
                        <div className="progress-bar">
                            <div className="progress" style={{ width: `${stats.cpuUsage}%` }}></div>
                        </div>
                        <p>{stats.cpuUsage}</p>
                    </div>
                    <div className="health-stat">
                        <h3>Mémoire</h3>
                        <div className="progress-bar">
                            <div className="progress" style={{ width: `${stats.memoryUsage}%` }}></div>
                        </div>
                        <p>{stats.memoryUsage}</p>
                    </div>
                    <div className="health-stat">
                        <h3>Disque</h3>
                        <div className="progress-bar">
                            <div className="progress" style={{ width: `${stats.diskUsage}%` }}></div>
                        </div>
                        <p>{stats.diskUsage}</p>
                    </div>
                </div>
            </section>

            <section className="security-section">
                <h2><Shield size={20} /> Sécurité</h2>
                <div className="security-alerts">
                    {securityAlerts.map((alert, index) => (
                        <SecurityAlert key={index} alert={alert} />
                    ))}
                </div>
            </section>

            <section className="notifications-section">
                <h2><Bell size={20} /> Notifications récentes</h2>
                <ul className="notification-list">
                    {notifications.map((notif, index) => (
                        <li key={index} className="notification-item">
                            <Zap size={18} />
                            <div className="notification-content">
                                <p>{notif.message}</p>
                                <span>{notif.time}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </section>
        </div>
    );
};

export default Overview;