import React, { useState } from 'react';
import { Book, Users, Clock, Star, ChevronDown, CheckCircle, ArrowRight } from 'lucide-react';
import '../styles/Formations.scss';

const FormationCard = ({ icon: Icon, title, description, comingSoon }) => (
  <div className={`formation-card ${comingSoon ? 'coming-soon' : ''}`}>
    <div className="formation-card-content">
      <div className="formation-card-header">
        <div className="icon-container">
          <Icon />
        </div>
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
      {comingSoon && <span className="coming-soon-badge">Bientôt disponible</span>}
    </div>
  </div>
);

const TestimonialCard = ({ name, role, content }) => (
  <div className="testimonial-card">
    <div className="quote-mark">"</div>
    <p className="testimonial-content">{content}</p>
    <div className="testimonial-author">
      <div className="author-avatar">
        {name.charAt(0)}
      </div>
      <div className="author-info">
        <div className="author-name">{name}</div>
        <div className="author-role">{role}</div>
      </div>
    </div>
  </div>
);

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button
        className="faq-question"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <span>{question}</span>
        <ChevronDown className={isOpen ? 'rotate' : ''} />
      </button>
      {isOpen && <p className="faq-answer">{answer}</p>}
    </div>
  );
};

const Formations = () => {
  const formations = [
    {
      icon: Book,
      title: "OSINT Fondamentaux",
      description: "Maîtrisez les bases de l'OSINT et développez vos compétences en recherche d'informations en sources ouvertes.",
      comingSoon: true
    },
    {
      icon: Users,
      title: "Investigation Avancée",
      description: "Approfondissez vos techniques d'investigation numérique et apprenez à mener des enquêtes complexes.",
      comingSoon: true
    },
    {
      icon: Clock,
      title: "OSINT en Temps Réel",
      description: "Découvrez comment utiliser l'OSINT pour surveiller et analyser les informations en temps réel.",
      comingSoon: true
    }
  ];

  const features = [
    {
      icon: CheckCircle,
      title: "Expertise Reconnue",
      description: "Nos formations sont conçues et animées par des experts reconnus dans le domaine de l'OSINT."
    },
    {
      icon: Users,
      title: "Apprentissage Interactif",
      description: "Participez à des ateliers pratiques et des études de cas pour une expérience d'apprentissage immersive."
    },
    {
      icon: Star,
      title: "Certification Ozzzint",
      description: "Obtenez une certification reconnue dans l'industrie pour valoriser vos compétences en OSINT."
    }
  ];

  const testimonials = [
    {
      name: "Julien Durand",
      role: "Analyste en Cybersécurité",
      content: "J'ai hâte de suivre les formations Ozzzint. Leur réputation dans le domaine de l'OSINT est excellente."
    },
    {
      name: "Emilie Rousseau",
      role: "Journaliste d'Investigation",
      content: "Les outils Ozzzint m'ont déjà beaucoup aidée. Je suis impatiente de pouvoir me former davantage avec leurs experts."
    }
  ];

  const faqs = [
    {
      question: "Quand les formations seront-elles disponibles ?",
      answer: "Nous travaillons actuellement sur le contenu de nos formations pour vous offrir la meilleure expérience d'apprentissage possible. Nous prévoyons de lancer nos premières formations dans les prochains mois. Inscrivez-vous à notre newsletter pour être informé dès leur disponibilité !"
    },
    {
      question: "Quel niveau est requis pour suivre vos formations ?",
      answer: "Nos formations s'adresseront à tous les niveaux, du débutant à l'expert. Chaque formation précisera les prérequis nécessaires, permettant à chacun de trouver un parcours adapté à son niveau et à ses objectifs."
    },
    {
      question: "Les formations seront-elles en ligne ou en présentiel ?",
      answer: "L'ensemble de nos formations se déroulent exclusivement en ligne. Vous pourrez donc suivre nos cours/formations depuis votre canapé !"
    }
  ];

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  }


  return (
    <div className="nos-formations">
      <div className="container">
        <div className="header">
          <div className="title-container">
            <Book size={48} />
            <h1>Nos Formations</h1>
          </div>
          <p className="subtitle">
            Développez vos compétences en OSINT avec Ozzzint.fr.<br/> Nos formations à venir vous permettront de maîtriser les techniques d'investigation en sources ouvertes de manière éthique et professionnelle.
          </p>
        </div>

        <section className="formations-preview">
          <h2>Découvrez Nos Futures Formations</h2>
          <div className="formations-grid">
            {formations.map((formation, index) => (
              <FormationCard key={index} {...formation} />
            ))}
          </div>
        </section>

        <section className="features">
          <h2>Pourquoi Choisir Nos Formations ?</h2>
          <div className="features-grid">
            {features.map((feature, index) => (
              <div key={index} className="feature-card">
                <div className="feature-icon">
                  <feature.icon size={32} />
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="testimonials">
          <h2>Ce Que Disent Nos Futurs Apprenants</h2>
          <div className="testimonials-grid">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard key={index} {...testimonial} />
            ))}
          </div>
        </section>

        <section className="faq">
          <h2>Questions Fréquentes</h2>
          <div className="faq-container">
            {faqs.map((faq, index) => (
              <FAQItem key={index} {...faq} />
            ))}
          </div>
        </section>

        <section className="cta">
          <div className="cta-content">
            <h2>Prêt à Développer Vos Compétences en OSINT ?</h2>
            <p>Soyez le premier informé du lancement de nos formations et bénéficiez d'offres exclusives !</p>
            <div className="button-group">
              <button className="btn primary" onClick={() => openInNewTab("https://discord.com/invite/QqjYV8HpYE")}>
                S'inscrire à la newsletter <ArrowRight size={16} />
              </button>
              <button className="btn secondary" onClick={() => openInNewTab("https://discord.com/invite/QqjYV8HpYE")}>En savoir plus</button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Formations;