import React, { useState } from 'react';
import '../styles/Navbar.scss';
import logo from '../img/Banner_transparent.png';
import { useNavigate } from 'react-router-dom';
import { Menu, TargetIcon, UserIcon, Clock, Bug, Zap, X, Settings, User, HomeIcon, CircleUserIcon, BookText } from 'lucide-react';
import { useUser } from '../contexts/UserContext';


const Navbar = () => {
  const { user, isAuth, isStaff, logout } = useUser();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [isSelected, setIsSelected] = useState('');
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);


  const redirect = () => {
    navigate('/');
    if (showMenu) setShowMenu(false);
  }

  const redirectToMissions = () => {
    navigate('/our-missions');
    setIsSelected('missions');
    if (showMenu) setShowMenu(false);
  }

  const redirectToTeam = () => {
    navigate('/our-team');
    setIsSelected('team');
    if (showMenu) setShowMenu(false);
  }

  const redirectToBlog = () => {
    navigate('/blog');
    setIsSelected('blog');
    if (showMenu) setShowMenu(false);
  }

  const redirectToApiPricingDocs = () => {
    navigate('/api-pricing-docs');
    setIsSelected('api');
    if (showMenu) setShowMenu(false);
  }

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }

  const openUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  }

  const closeUserMenu = () => {
    setIsUserMenuOpen(false);
  }

  const redirectToHome = () => {
    // si nous sommes déjà sur la page d'accueil, on refresh le composant
    if (window.location.pathname === '/') {
      window.location.reload();
    }

    navigate('/');
    setIsSelected('home');
    if (showMenu) setShowMenu(false);
  }

  const redirectToUserParams = () => {
    navigate('/params');
    if (showMenu) setShowMenu(false);
    closeUserMenu();
  }

  const redirectToDashboard = () => {
    navigate('/manager');
    if (showMenu) setShowMenu(false);
    closeUserMenu();
  }

  const redirectToFormations = () => {
    navigate('/formations');
    setIsSelected('formations');
    if (showMenu) setShowMenu(false);
    closeUserMenu();
  }

  const logoutUser = () => {
    // remove db_token & user in local storage
    localStorage.removeItem('db_token');
    localStorage.removeItem('user');
    // reload the page
    window.location.reload();
  }

  return (
    <nav className="navbar">
      <div className="logo">
        <img src={logo} alt="logo" onClick={redirect} />
      </div>
      <div className="nav-links">
        <a onClick={redirectToHome} className={isSelected === 'home' ? 'selected' : ''}><HomeIcon size={16} />Accueil</a>
        <a onClick={redirectToMissions} className={isSelected === 'missions' ? 'selected' : ''}><TargetIcon size={16} />Notre Mission</a>
        <a onClick={redirectToFormations} className={isSelected === 'formations' ? 'selected' : ''}><BookText size={16} />Formations</a>
        <a onClick={redirectToApiPricingDocs} className={isSelected === 'api' ? 'selected' : ''}><Zap size={16} />API</a>

        <a onClick={redirectToTeam} className={isSelected === 'team' ? 'selected' : ''}><UserIcon size={16} />L'équipe</a>
        <a onClick={redirectToBlog} className={isSelected === 'blog' ? 'selected' : ''}><Bug size={16} />Blog</a>
      </div>
      <div className="nav-actions">
        {isAuth ? (
          <>
            <button className="menu" onClick={openUserMenu}>
              <CircleUserIcon size={18} color='#c9d1d9' />
              <p>{user.user.name ? user.user.name : 'Non connecté'}</p>
            </button>


          </>
        ) : (
          <>
            <button className="button" onClick={() => navigate('/login')}>Se connecter</button>
            <button className="button" onClick={() => navigate('/register')}>S'inscrire</button>
          </>
        )
        }
      </div >

      {isUserMenuOpen && (
        <div className="user-menu">
          <div className="user-menu-actions">
            <a onClick={redirectToUserParams}><User size={16} />Compte</a>
            {isStaff && <a onClick={redirectToDashboard}><User size={16} />Zone staff</a>}
            <a onClick={redirectToUserParams}><Clock size={16} />Historique</a>
            <a onClick={redirectToApiPricingDocs}><Zap size={16} />Accès API</a>
            <a onClick={redirectToUserParams}><Settings size={16} />Paramètres</a>
            <a onClick={logoutUser}><X size={16} />Se déconnecter</a>
          </div>
        </div>
      )}

      <div className="menu-icon">
        {showMenu ? <X onClick={toggleMenu} /> : <Menu onClick={toggleMenu} />}
      </div>

      {
        showMenu ? (
          <div className="mobile-menu">
            <a onClick={redirectToMissions}><TargetIcon size={16} />Notre Mission</a>
            <a onClick={redirectToApiPricingDocs}><Zap size={16} />API</a>
            <a onClick={redirectToTeam}><UserIcon size={16} />L'équipe</a>
            <a onClick={redirectToBlog}><Bug size={16} />Blog</a>

            <hr />
            {isAuth ? (
              <div className="mobile-user-actions">
                <div className="mobile-user-info">
                  <div className="mobile-user-avatar">
                    <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.4 13.8C8.4 13.8 9.75 15.6 12 15.6C14.25 15.6 15.6 13.8 15.6 13.8M14.7 9.3H14.709M9.3 9.3H9.309M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM15.15 9.3C15.15 9.54853 14.9485 9.75 14.7 9.75C14.4515 9.75 14.25 9.54853 14.25 9.3C14.25 9.05147 14.4515 8.85 14.7 8.85C14.9485 8.85 15.15 9.05147 15.15 9.3ZM9.75 9.3C9.75 9.54853 9.54853 9.75 9.3 9.75C9.05147 9.75 8.85 9.54853 8.85 9.3C8.85 9.05147 9.05147 8.85 9.3 8.85C9.54853 8.85 9.75 9.05147 9.75 9.3Z" stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <p>{user.name}</p>

                </div>
                <button className="button">Se déconnecter</button>
              </div>
            ) : (
              <div className="mobile-actions">
                <button className="button">Se connecter</button>
                <button className="button">S'inscrire</button>
              </div>
            )}
          </div>
        ) : null
      }
    </nav >
  );
};

export default Navbar;
