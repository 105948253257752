import React, { useState, useEffect } from 'react';
import { Search, Loader, ArrowRight, X, Save, Trash2, BrickWall, StarIcon, Heart, LucideBookOpen, Book, Lightbulb, List, Grid, Eye, Shield, Zap, Clock, Gift, Star, FileSpreadsheet, PartyPopper } from 'lucide-react';
import '../styles/SearchPage.scss';
import SearchForm from './SearchForm';
import { getWsLink } from '../utils/pointer';
import StoreModal from './StoreModal';
import logo from '../img/Banner_transparent.png'
import DomainResults from './DomainResults';
import IpResults from './IpResults';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import Seo from './Seo';



const SearchPage = () => {
    const [searchType, setSearchType] = useState('domain');
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [moduleResults, setModuleResults] = useState({});
    const [error, setError] = useState(null);
    const [tokensRemaining, setTokensRemaining] = useState(100);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [selectedModule, setSelectedModule] = useState(null);
    const [firstSearch, setFirstSearch] = useState(true);
    const [summary, setSummary] = useState('');
    const [savedSearches, setSavedSearches] = useState([]);
    const [hasSearched, setHasSearched] = useState(false);
    const [showStore, setShowStore] = useState(false);
    const [socket, setSocket] = useState(null);

    const [searchComplete, setSearchComplete] = useState(false);
    const [viewMode, setViewMode] = useState('grid'); // 'grid' ou 'list'
    const [showSearchForm, setShowSearchForm] = useState(true);

    const [showSavedSearches, setShowSavedSearches] = useState(false);
    const [domainData, setDomainData] = useState(null);
    const [ipData, setIpData] = useState(null);
    const [duration, setDuration] = useState(0);
    const [canSearch, setCanSearch] = useState(false);
    const [tokensLeft, setTokensLeft] = useState(0);
    const [hasUpdate, setHasUpdate] = useState(true);

    useEffect(() => {

        const ws = new WebSocket(getWsLink() + `/ws`);

        ws.onopen = () => {
            setSocket(ws);
            if (localStorage.getItem('db_token')) {
                let clearedToken = localStorage.getItem('db_token').replace('"', '')
                if (clearedToken.includes('"')) clearedToken = clearedToken.replace('"', '');

                const messageHandshake = {
                    type: 'handshake',
                    token: clearedToken
                }
                ws.send(JSON.stringify(messageHandshake));
            }


        };
        let i = 0;
        ws.onmessage = (message) => {
            const data = JSON.parse(message.data);
            switch (data.type) {
                case 'handshakeResponse':
                    setCanSearch(true)
                    setTokensLeft(data.tokensLeft)
                    break;
                case 'errorHandshake':
                    // console.error("Handshake error", data.message)
                    break;
                case 'partial_result':
                    // console.info("Websocket partial result", data.module, data.searchType, data.data)
                    break;
                case 'domainCheckerResult':
                    setDomainData(data.data);
                    setIsSearching(false);
                    setFirstSearch(false);
                    setHasSearched(true);
                    break;
                case 'tError':
                    setError(data.message);
                    setIsSearching(false);
                    break;
                case 'searchComplete':

                    if (data.searchType === 'domain') {
                        setDomainData(data.data);
                        setDuration(data.analysis_time)
                        setIsSearching(false);
                        setFirstSearch(false);
                        setHasSearched(true);
                        setSearchComplete(true);
                        setTokensLeft(data.tokensLeft)
                        setSavedSearches([{
                            type: data.searchType,
                            searchValue: data.searchValue,
                            data: data.data
                        }]);
                    } else if (data.searchType === 'ipaddress') {
                        setIpData(data.data);
                        setDuration(data.analysis_time)
                        setIsSearching(false);
                        setFirstSearch(false);
                        setHasSearched(true);
                        setSearchComplete(true);
                        setTokensLeft(data.tokensLeft)
                        setSavedSearches([{
                            type: data.searchType,
                            searchValue: data.searchValue,
                            data: data.data
                        }]);
                    } else if (data.searchType === 'phone') {
                        setModuleResults(data.data);
                        setIsSearching(false);
                        setFirstSearch(false);
                        setHasSearched(true);
                        setSearchComplete(true);
                        setTokensLeft(data.tokensLeft)
                        setSavedSearches([{
                            type: data.searchType,
                            searchValue: data.searchValue,
                            data: data.data
                        }]);
                    }


                    break;
                case 'error':
                    setError(data.message);
                    setIsSearching(false);
                    break;
                default:
                    return;
            }
        }

        ws.onclose = () => {
            setSocket(null);
        };

        return () => {
            if (ws) ws.close();
        };
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();

        if (!canSearch) {
            setError("Vous devez être connecté pour effectuer une recherche.");
            return;
        }

        switch (searchType) {
            case 'phone':
                if (!/^\d{11}$/.test(searchValue)) {
                    setError("Veuillez entrer un numéro de téléphone valide.");
                    return;
                }
                break;
            case 'email':
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(searchValue)) {
                    setError("Veuillez entrer une adresse e-mail valide.");
                    return;
                }
                break;
            case 'username':
                if (!/^[a-zA-Z0-9_]{1,15}$/.test(searchValue)) {
                    setError("Veuillez entrer un nom d'utilisateur valide.");
                    return;
                }
                break;
            case 'ip':
                if (!/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(searchValue)) {
                    setError("Veuillez entrer une adresse IP valide.");
                    return;
                }
                break;
            case 'domain':
                if (!/^[a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{1,63})+$/.test(searchValue)) {
                    setError("Veuillez entrer un nom de domaine valide.");
                    return;
                }
                break;
            default:
                break;
        }

        if (firstSearch && !termsAccepted) {
            setError("Veuillez accepter les conditions d'utilisation.");
            return;
        }


        if (socket) {
            setIsSearching(true);
            setError(null);
            setSelectedModule(null);
            setSummary('');
            setShowSearchForm(false);
            setShowSavedSearches(false);

            switch (searchType) {

                case 'email':
                    setModuleResults({});

                    const messageEmail = {
                        type: 'search',
                        searchType,
                        searchValue,
                        token: localStorage.getItem('db_token')
                    }

                    socket.send(JSON.stringify(messageEmail));
                    break;
                case 'domain':

                    setModuleResults({});

                    const messageDomain = {
                        type: 'search',
                        searchType,
                        searchValue,
                        token: localStorage.getItem('db_token')
                    }

                    socket.send(JSON.stringify(messageDomain));
                    break;
                case 'ipaddress':

                    setModuleResults({});

                    const messageIpaddress = {
                        type: 'search',
                        searchType,
                        searchValue,
                        token: localStorage.getItem('db_token')
                    }

                    socket.send(JSON.stringify(messageIpaddress));
                    break;
                case 'phone':

                    setModuleResults({});

                    const messagePhone = {
                        type: 'search',
                        searchType,
                        searchValue,
                        token: localStorage.getItem('db_token')
                    }

                    socket.send(JSON.stringify(messagePhone));
                    break;

                case 'username':

                    setModuleResults({});

                    const messageUsername = {
                        type: 'search',
                        searchType,
                        searchValue,
                        token: localStorage.getItem('db_token')
                    }

                    socket.send(JSON.stringify(messageUsername));
                    break;
                default:
                    break;
            }


        } else {
            setError("La connexion au serveur n'est pas établie.");
        }

        setSearchComplete(false);

        setTimeout(() => {
            setIsSearching(false);
            setFirstSearch(false);
            setHasSearched(true);
        }, 6000);
    };

    const renderInput = () => {
        const inputProps = {
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value),
            className: "search-input"
        };

        switch (searchType) {
            case 'phone': return <input type="tel" placeholder="Numéro de téléphone" {...inputProps} />;
            case 'email': return <input type="email" placeholder="Adresse e-mail" {...inputProps} />;
            case 'username': return <input type="text" placeholder="Nom d'utilisateur" {...inputProps} />;
            case 'ipaddress': return <input type="text" placeholder="Adresse IP" {...inputProps} />;
            case 'domain': return <input type="text" placeholder="Nom de domaine" {...inputProps} />;
            default: return null;
        }
    };

    const deleteSavedSearch = (index) => {
        setSavedSearches(prev => prev.filter((_, i) => i !== index));
    };

    const viewSavedSearchResults = (index) => {
        const savedSearch = savedSearches[index];
        setSearchType(savedSearch.type);
        setSearchValue(savedSearch.value);
        setModuleResults(savedSearch.results);
        setSummary(savedSearch.summary);
        setShowSearchForm(false);
        setShowSavedSearches(false);
        setHasSearched(true);
    };



    const [selectedOffer, setSelectedOffer] = useState(null);

    const offers = [
        { id: 1, name: "Débutant", tokens: 50, rate: 5, price: 7.49, popular: false },
        { id: 2, name: "Expérimenté", tokens: 200, rate: 20, price: 24.99, popular: true },
        { id: 3, name: "Pro", tokens: 500, rate: 50, price: 59.99, popular: false },
        { id: 4, name: "Expert", tokens: 1000, rate: 100, price: 127.99, popular: false },
    ];

    const subscription = {
        name: "Permis de Chasseur",
        tokens: "∞",
        rate: 100,
        price: 89.99,
        originalPrice: 299.99,
    };

    const handleOfferClick = (offer) => {
        setSelectedOffer(offer);
    };

    const onClose = () => {
        setShowStore(false);
    };

    const showStoreModal = () => {
        setShowStore(!showStore);
    };

    const StoreModalDebug = () => {
        return (
            <div className="store-modal">
                <div className="modal-content">
                    <h2>Un chasseur sachant chasser...</h2>
                    <p className="subtitle">Choississez le type de chasseur que vous êtes</p>
                    <button onClick={onClose} className="close-button">
                        <X size={24} />
                    </button>
                    <div className="token-offers">
                        {offers.map((offer) => (
                            <div
                                key={offer.id}
                                className={`offer ${offer.popular ? 'popular' : ''} ${selectedOffer === offer ? 'selected' : ''}`}
                                onClick={() => handleOfferClick(offer)}
                            >
                                {offer.popular && <span className="popular-badge">Le plus Populaire</span>}
                                <h3>{offer.name}</h3>
                                <div className="offer-details">
                                    <p className="tokens"><Zap size={18} /> {offer.tokens} tokens</p>
                                    <p className="rate"><Clock size={18} /> {offer.rate} tokens/heure</p>
                                </div>
                                <p className="price">${offer.price}</p>
                                <button className="buy-button">Choisir</button>
                            </div>
                        ))}
                    </div>
                    <div className="subscription-offer">
                        <div className="offer subscription">
                            <h3>{subscription.name}</h3>
                            <div className="offer-details">
                                <p className="tokens"><Zap size={18} /> {subscription.tokens} tokens</p>
                                <p className="rate"><Clock size={18} /> {subscription.rate}+ tokens/heure</p>
                            </div>
                            <p className="price">
                                ${subscription.price}/mois
                                <span className="original-price">${subscription.originalPrice}</span>
                            </p>
                            <button className="buy-button">Obtenir votre permis</button>
                        </div>
                        <div className="subscription-benefits">
                            <h4>Avantages du permis de chasseur</h4>
                            <ul>
                                <li><Shield size={18} /> Support Prioritaire</li>
                                <li><Zap size={18} /> Traitement Plus Rapide</li>
                                <li><Gift size={18} /> Fonctionnalités Exclusives</li>
                                <li><Star size={18} /> Accès à des modules Premium</li>
                                <li><Shield size={18} /> Grade "Chasseur" sur notre Discord</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    const handleShowStore = () => {
        setShowStore(!showStore);
    };

    const reloadPage = () => {
        window.location.reload();
    }

    const toggleViewMode = () => {
        setViewMode(prevMode => prevMode === 'grid' ? 'list' : 'grid');
    };

    const renderResults = () => {
        if (searchType === 'domain' && domainData) {
            return <DomainResults data={domainData} domain={searchValue} duration={duration} />
        }

        if (searchType === 'ipaddress' && ipData) {
            return <IpResults data={ipData} ip={searchValue} duration={duration} />
        }
    };

    const handleUpdate = () => {
        setHasUpdate(!hasUpdate);
    }

    return (
        <>
            <Seo
                title="Ozzzint | Plateforme de Recherche OSINT et Sécurité"
                description="Effectuez des recherches OSINT avancées, analysez la sécurité et collaborez avec la communauté cybersécurité. Outils professionnels pour l'investigation numérique."
                canonicalUrl="https://ozzzint.fr"
                keywords="OSINT, recherche cybersécurité, investigation numérique, analyse sécurité, communauté cyber"
                author="Ozzzint"
                ogImage="https://ozzzint.fr/og-image.jpg"
            />
            <div className="search-component">
                <div className="header" onClick={reloadPage}>
                    <img src={logo} alt="Ozzzint" className='logo' />
                    <span>Lookup</span>
                </div>
                <div className="search-container">



                    <div className="tokens-display" onClick={handleShowStore}><StarIcon size={16} color='yellow' /> Tokens restants: {tokensLeft}</div>
                    {error && (
                        <div className="error-message">
                            {error}
                            <button onClick={() => setError(null)} className="close-button">
                                <X size={16} />
                            </button>
                        </div>
                    )}
                    {!hasSearched && (
                        <>
                            <SearchForm firstSearch={searchType} handleSearch={handleSearch} renderInput={renderInput} isSearching={isSearching} termsAccepted={termsAccepted} setTermsAccepted={setTermsAccepted} searchType={searchType} setSearchType={setSearchType} />

                            {hasUpdate && (
                                <div className="update-info" onClick={handleUpdate}>
                                    <div className="update-icon"><PartyPopper size={24} color='white' /></div>
                                    <h4>Mise à jour des modules de recherche
                                        <p>Nous avons ajouté et optimisé les modules de recherche pour une meilleure expérience utilisateur et des résultats plus rapides.</p>
                                        <span>Version 1.2.1</span>
                                    </h4>
                                    <div className='close-button'>
                                        <X size={16} />
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {showSearchForm && hasSearched && (
                        <SearchForm
                            firstSearch={searchType}
                            handleSearch={handleSearch}
                            renderInput={renderInput}
                            isSearching={isSearching}
                            termsAccepted={termsAccepted}
                            setTermsAccepted={setTermsAccepted}
                            searchType={searchType}
                            setSearchType={setSearchType}
                        />
                    )}

                    {savedSearches.length > 0 && (
                        <div className="saved-searches">
                            <h3>Recherches sauvegardées</h3>
                            {savedSearches.map((search, index) => (
                                <div key={index} className="saved-search">
                                    <h4>{search.type}: {search.searchValue}</h4>
                                    <div className="saved-search-buttons">
                                        <button onClick={() => viewSavedSearchResults(index)} className="delete-button">
                                            <Eye size={20} /> Voir
                                        </button>
                                        <button onClick={() => deleteSavedSearch(index)} className="delete-button">
                                            <Trash2 size={20} /> Supprimer
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {renderResults()}
                </div>

                {showStore && <StoreModal setShowStore={setShowStore} />}
            </div>
        </>
    );
};

export default SearchPage;
