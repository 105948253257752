import React, { useState } from 'react';
import '../styles/OurMissions.scss';
import {
  Target, Shield, Users, Lightbulb, ArrowRight, ChevronDown,
  Award, Book, Globe, Clock, Check, Coffee, BlocksIcon
} from 'lucide-react';

const MissionCard = ({ icon: Icon, title, description }) => (
  <div className="mission-card">
    <div className="mission-card-content">
      <div className="mission-card-header">
        <div className="icon-container">
          <Icon />
        </div>
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
    </div>
  </div>
);

const StatCard = ({ icon: Icon, value, label }) => (
  <div className="stat-card">
    <Icon size={32} />
    <div className="stat-value">{value}</div>
    <div className="stat-label">{label}</div>
  </div>
);

const TestimonialCard = ({ name, role, content }) => (
  <div className="testimonial-card">
    <div className="quote-mark">"</div>
    <p className="testimonial-content">{content}</p>
    <div className="testimonial-author">
      <div className="author-avatar">
        {name.charAt(0)}
      </div>
      <div className="author-info">
        <div className="author-name">{name}</div>
        <div className="author-role">{role}</div>
      </div>
    </div>
  </div>
);

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button
        className="faq-question"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <span>{question}</span>
        <ChevronDown className={isOpen ? 'rotate' : ''} />
      </button>
      {isOpen && <p className="faq-answer">{answer}</p>}
    </div>
  );
};

const OurMissions = () => {
  const missions = [
    {
      icon: Shield,
      title: "Sécurité et Transparence",
      description: "Nous garantissons des recherches éthiques et transparentes, respectant la vie privée tout en fournissant des informations précieuses."
    },
    {
      icon: Users,
      title: "Communauté Active",
      description: "Rejoignez une communauté dynamique de cerveaux partageant leurs connaissances et expériences en OSINT."
    },
    {
      icon: Lightbulb,
      title: "Innovation Continue",
      description: "Nous développons constamment de nouveaux outils et méthodologies pour rester à la pointe de l'investigation numérique."
    }
  ];

  const stats = [
    { icon: Users, value: "200+", label: "Utilisateurs Actifs" },
    { icon: BlocksIcon, value: "500+", label: "Modules de recherche" },
    { icon: Coffee, value: "10k+", label: "Recherches Effectuées" },
    { icon: Clock, value: "24/7", label: "Support Disponible" }
  ];

  const features = [
    {
      icon: Book,
      title: "Formation Continue",
      description: "Accédez à des ressources pédagogiques exclusives et participez à des workshops mensuels animés par des experts.",
      benefits: [
        "Cours exclusifs (Bientôt disponible)",
        "Workshops hebdomadaires (Bientôt disponible)",
        "Ressources consultable"
      ]
    },
    {
      icon: Award,
      title: "Outils Professionnels",
      description: "Utilisez des outils OSINT de pointe, développés et optimisés par notre équipe !",
      benefits: [
        "Mises à jour régulières",
        "Support technique dédié",
        "Personnalisation avancée"
      ]
    }
  ];

  const testimonials = [
    {
      name: "Sophie Martin",
      role: "Journaliste d'Investigation",
      content: "Ozzzint a révolutionné ma façon de mener des enquêtes. Les outils sont puissants et la communauté est incroyablement enrichissante."
    },
    {
      name: "Thomas Dubois",
      role: "Expert en Cybersécurité",
      content: "La qualité des formations et le professionnalisme de l'équipe Ozzzint sont exceptionnels. Un must pour tout professionnel de la sécurité."
    },
    {
      name: "Marie Leroy",
      role: "Chercheuse Indépendante",
      content: "Grâce à Ozzzint, j'ai pu développer mes compétences en OSINT de manière éthique et efficace. La plateforme est un véritable game-changer."
    }
  ];

  const faqs = [
    {
      question: "Qu'est-ce que l'OSINT ?",
      answer: "L'OSINT (Open Source Intelligence) est la collecte et l'analyse d'informations provenant de sources publiques pour produire des renseignements exploitables."
    },
    {
      question: "Comment Ozzzint garantit-il la sécurité des utilisateurs ?",
      answer: "Nous utilisons des protocoles de sécurité avancés, ne stockons aucune donnée sensible et formons nos utilisateurs aux meilleures pratiques de cybersécurité."
    },
    {
      question: "Qui peut utiliser Ozzzint ?",
      answer: "Ozzzint est conçu pour les professionnels de la cybersécurité, les journalistes d'investigation, les chercheurs et toute personne intéressée par l'OSINT éthique."
    },
    {
      question: "Existe-t-il des formations pour débutants ?",
      answer: "Absolument ! Nous proposons des parcours adaptés à tous les niveaux, du débutant à l'expert, avec un accompagnement personnalisé."
    }
  ];

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  }


  return (
    <div className="our-missions">
      <div className="container">
        <div className="header">
          <div className="title-container">
            <Target size={48} />
            <h1>Notre Mission</h1>
          </div>
          <p className="subtitle">
            Ozzzint.fr révolutionne l'accès à l'information publique en démocratisant l'OSINT de manière éthique et sécurisée.
          </p>
        </div>

        <div className="stats-grid">
          {stats.map((stat, index) => (
            <StatCard key={index} {...stat} />
          ))}
        </div>

        <div className="missions-grid">
          {missions.map((mission, index) => (
            <MissionCard key={index} {...mission} />
          ))}
        </div>

        <section className="features">
          <h2>Pourquoi Choisir Ozzzint ?</h2>
          <div className="features-grid">
            {features.map((feature, index) => (
              <div key={index} className="feature-card">
                <div className="feature-icon">
                  <feature.icon size={32} />
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
                <ul className="benefits-list">
                  {feature.benefits.map((benefit, i) => (
                    <li key={i}>
                      <Check size={16} />
                      {benefit}
                    </li>
                  ))}
                </ul>
                <button className="btn primary">
                  En savoir plus <ArrowRight size={16} />
                </button>
              </div>
            ))}
          </div>
        </section>

        <section className="testimonials">
          <h2>Ils Nous Font Confiance</h2>
          <div className="testimonials-grid">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard key={index} {...testimonial} />
            ))}
          </div>
        </section>

        <section className="faq">
          <h2>Questions Fréquentes</h2>
          <div className="faq-container">
            {faqs.map((faq, index) => (
              <FAQItem key={index} {...faq} />
            ))}
          </div>
        </section>

        <section className="cta">
          <div className="cta-content">
            <h2>Prêt à Nous Rejoindre ?</h2>
            <p>Devenez membre de la communauté Ozzzint et accédez à des ressources exclusives</p>
            <div className="button-group">
              <button onClick={() => openInNewTab("https://discord.com/invite/QqjYV8HpYE")} className="btn primary">Rejoindre la communauté</button>
              <button onClick={() => openInNewTab("https://discord.com/invite/QqjYV8HpYE")} className="btn secondary">Demander une démo</button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OurMissions;