import React, { useState } from 'react';
import { X, House, Plus, ChartArea, Save, ArrowUp, ArrowDown, Trash2, Eye, EyeOffIcon, Text, Heading, Code, Pilcrow, MessageSquareCode, MessageSquareQuote, List, Space, ImageIcon, Video } from 'lucide-react';
import '../styles/Blog.scss'
import { getLink } from '../utils/pointer';

const contentTypes = [
    { value: 'header', label: 'Titre', icon: <Heading size={14} />, desc: "Votre titre ici" },
    { value: 'paragraph', label: 'Paragraphe', icon: <Pilcrow size={14} />, desc: "Votre paragraphe ici" },
    { value: 'quote', label: 'Citation', icon: <MessageSquareQuote size={14} />, desc: "Votre citation ici" },
    { value: 'list', label: 'Liste', icon: <List size={14} />, desc: "Votre list ici (séparé par <nl>)" },
    { value: 'code', label: 'Code', icon: <Code size={14} />, desc: "Votre code ici" },
    { value: 'image', label: 'Image', icon: <ImageIcon size={14} />, desc: "Votre url de l'image ici" },
    { value: 'video', label: 'Vidéo', icon: <Video size={14} />, desc: "Votre url de la vidéo ici" },
    { value: 'spacer', label: 'Spacer', icon: <Space size={14} />, desc: "Taille du spacer"}
];

const BlogModal = ({ showModal, toggleModal, isStaff }) => {
    const [selectedTab, setSelectedTab] = useState('home');
    const [formData, setFormData] = useState({
        title: '',
        category: '',
        content: [],
    });
    const [showPreview, setShowPreview] = useState(false);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const addContentBlock = (type) => {
        setFormData(prevState => ({
            ...prevState,
            content: [...prevState.content, { type, content: '' }]
        }));
    };

    const updateContentBlock = (index, content) => {
        setFormData(prevState => ({
            ...prevState,
            content: prevState.content.map((block, i) =>
                i === index ? { ...block, content } : block
            )
        }));
    };

    const removeContentBlock = (index) => {
        setFormData(prevState => ({
            ...prevState,
            content: prevState.content.filter((_, i) => i !== index)
        }));
    };

    const moveContentBlock = (fromIndex, toIndex) => {
        setFormData(prevState => {
            const newContent = Array.from(prevState.content);
            const [reorderedItem] = newContent.splice(fromIndex, 1);
            newContent.splice(toIndex, 0, reorderedItem);
            return { ...prevState, content: newContent };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log('Soumission du formulaire:', formData);

        const bodyToSend = formData;

        console.info("Body to send", bodyToSend)

        const fetchPosts = async () => {
            const response = await fetch(getLink() + '/blog', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('db_token'),
                },
                body: JSON.stringify(bodyToSend), // Envoi des données du fichier
            });

            if (!response.ok) {
                return console.info("Erreur lors de l'envoi: ", response)
            }

            const data = await response.json();
            console.log('Post sent successfully:', data);
        }

        fetchPosts()
    };

    const renderContentBlock = (block, index) => {
        const ContentInput = block.type === 'paragraph' || block.type === 'quote' ? 'textarea' : 'input';

        return (
            <div key={index} className="content-block">
                <div className="content-block-header">
                    <span className="content-type">{contentTypes.find(t => t.value === block.type).label}</span>
                    <div className="content-block-actions">
                        <div onClick={() => moveContentBlock(index, index - 1)} disabled={index === 0}>
                            <ArrowUp size={16} />
                        </div>
                        <div onClick={() => moveContentBlock(index, index + 1)} disabled={index === formData.content.length - 1}>
                            <ArrowDown size={16} />
                        </div>
                        <div onClick={() => removeContentBlock(index)}>
                            <Trash2 size={16} />
                        </div>
                    </div>
                </div>
                <ContentInput
                    value={block.content}
                    onChange={(e) => updateContentBlock(index, e.target.value)}
                    placeholder={contentTypes.find(t => t.value === block.type).desc.toLowerCase()}
                />
            </div>
        );
    };

    if (!showModal) return null;

    return (
        <div className='blog-modal'>
            <div className='blog-modal-content'>
                <button className='close-button' onClick={toggleModal}>
                    <X size={24} />
                </button>

                <div className='blog-modal-tabs'>
                    <button onClick={() => handleTabClick('home')} className={selectedTab === 'home' ? 'active' : ''}>
                        <House size={18} /> Accueil
                    </button>
                    <button onClick={() => handleTabClick('add')} className={selectedTab === 'add' ? 'active' : ''}>
                        <Plus size={18} /> Ajouter
                    </button>
                    <button onClick={() => handleTabClick('stats')} className={selectedTab === 'stats' ? 'active' : ''}>
                        <ChartArea size={18} /> Stats
                    </button>
                </div>

                <div className='blog-modal-body'>
                    {selectedTab === 'home' && (
                        <div className='tab-content'>
                            <h3>Tableau de bord</h3>
                            <p>Bienvenue dans l'interface d'administration du blog.</p>
                        </div>
                    )}

                    {selectedTab === 'add' && (
                        <div className='tab-content'>
                            <h3>Ajouter un nouveau post</h3>
                            <form onSubmit={handleSubmit}>
                                <div className='form-group'>
                                    <label htmlFor='title'>Titre</label>
                                    <input
                                        type='text'
                                        id='title'
                                        name='title'
                                        value={formData.title}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='title'>Description</label>
                                    <input
                                        type='text'
                                        id='desc'
                                        name='desc'
                                        value={formData.desc}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='title'>Thumbnail</label>
                                    <input
                                        type='text'
                                        id='image'
                                        name='image'
                                        value={formData.image}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='title'>Auteur</label>
                                    <input
                                        type='text'
                                        id='author'
                                        name='author'
                                        value={formData.author}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='category'>Catégorie</label>
                                    <select
                                        id='category'
                                        name='category'
                                        value={formData.category}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value=''>Sélectionnez une catégorie</option>
                                        <option value='news'>News</option>
                                        <option value='guides'>Tutoriel</option>
                                        <option value='tips'>Conseils</option>
                                        <option value='other'>Autre</option>
                                    </select>
                                </div>

                                <div className='add-content-buttons'>
                                    {contentTypes.map((type) => (
                                        <button
                                            key={type.value}
                                            type="button"
                                            onClick={() => addContentBlock(type.value)}
                                            className='add-content-button'
                                        >
                                            {type.icon} {type.label}
                                        </button>
                                    ))}
                                </div>

                                <div className='content-blocks'>
                                    {formData.content.map((block, index) => renderContentBlock(block, index))}
                                </div>



                                <button type='submit' className='submit-button'>
                                    <Save size={18} /> Publier
                                </button>
                            </form>
                            <button onClick={() => setShowPreview(!showPreview)} className='preview-button'>
                                {showPreview ? <EyeOffIcon size={16} color='white' /> : <Eye size={16} color='white' />}
                                {/* {showPreview ? 'Masquer ' : 'Afficher '} */}
                                Prévisualisation
                            </button>
                            {showPreview && (
                                <div className='preview'>
                                    <h2>{formData.title}</h2>
                                    <p>Catégorie: {formData.category}</p>
                                    {formData.content.map((block, index) => {
                                        switch (block.type) {
                                            case 'header':
                                                return <h3 key={index}>{block.content}</h3>;
                                            case 'paragraph':
                                                return <p key={index}>{block.content}</p>;
                                            case 'quote':
                                                return <blockquote key={index}>{block.content}</blockquote>;
                                            case 'list':
                                                return (
                                                    <ul key={index}>
                                                        {block.content.split('<nl>').map((item, i) => (
                                                            <li key={i}>{item}</li>
                                                        ))}
                                                    </ul>
                                                );
                                            case 'code':
                                                return <pre key={index}><code>{block.content}</code></pre>;
                                            default:
                                                return null;
                                        }
                                    })}
                                </div>
                            )}
                        </div>
                    )}

                    {selectedTab === 'stats' && (
                        <div className='tab-content'>
                            <h3>Statistiques</h3>
                            <p>Les statistiques du blog seront affichées ici.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BlogModal;