import React from 'react';
import '../styles/Footer.scss';
import { useNavigate } from 'react-router-dom';
import { FaTwitter, FaLinkedinIn, FaGithub, FaDiscord } from 'react-icons/fa'; // Icons from react-icons

const Footer = () => {
    const navigate = useNavigate();

    
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    }

    const redirectToLegals = () => {
        navigate('/legals');
    }

    const redirectToCredits = () => {
        navigate('/credits');
    }

  return (
    <footer className="footer">
      <div className="footer-links">
        <a onClick={redirectToLegals}>Légal</a>
        <a onClick={redirectToCredits}>Crédits</a>
      </div>

      <div className="social-icons">
        <a aria-label="Discord" onClick={() => openInNewTab("https://discord.com/invite/QqjYV8HpYE")}>
          <FaDiscord size={22}/>
        </a>
        <a aria-label="Twitter" onClick={() => openInNewTab("https://twitter.com")}>
          <FaTwitter size={22} />
        </a>
        <a aria-label="GitHub" onClick={() => openInNewTab("https://github.com/DataSeekerMaster")}>
          <FaGithub size={22} />
        </a>
      </div>

      <div className="copyright">
        &copy; {new Date().getFullYear()} Ozzzint.fr.
      </div>
    </footer>
  );
};

export default Footer;
