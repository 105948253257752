import React, { useState, useEffect } from 'react';
import { FileWarning, Key, UserPlus } from 'lucide-react';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import Seo from '../components/Seo';
import logo from '../img/login.png';
import logo_register from '../img/register.png';
import '../styles/Login.scss';

const Login = () => {
  const { user, login, isAuth } = useUser();
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [currentView, setCurrentView] = useState('login');
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      navigate('/');
    }
  }, [isAuth]);


  const handleLogin = async () => {
    if (name.trim() === '') {
      setError('Veuillez entrer votre nom d\'utilisateur');
      return;
    }

    // verify that name is only letters and Numbers
    const nameRegex = /^[a-zA-Z0-9]+$/;
    if (!nameRegex.test(name)) {
      setError('Le nom d\'utilisateur ne peut contenir que des lettres et des chiffres');
      return;
    }

    // Verify that there is a password and that not empty
    if (password.trim() === '') {
      setError('Veuillez entrer votre mot de passe');
      return;
    }

    const isLogin = await login(name, password);

    if (isLogin) {
      setCurrentView('mfa');
    } else {
      setError(`Nom d'utilisateur ou mot de passe incorrect.`);
    }
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
    setError('');
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setError('');
  };




  useEffect(() => {
    if (error !== '') {
    }
  }, [error]);

  const renderLoginView = () => (
    <>
      <div className='header-setup'>
        <img src={logo} alt="Logo" className="logo" />
      </div>
      {error !== '' && (
        <div className="error">
          <FileWarning size={24} />
          <span>{error}</span>
        </div>
      )}
      <div className="setup-step">
        <p className='login-step'>Nom d'utilisateur</p>
        <input type="text" placeholder="Nom d'utilisateur" onChange={handleChangeName} />
        <p className='login-step'>Mot de passe</p>
        <input type="password" placeholder="Mot de passe" onChange={handleChangePassword} />

        <button className="next-button" onClick={() => handleLogin()}>
          Connexion
        </button>
      </div>

      <div className='footer-login'>
        <a><Key size={16} />Mot de passe oublié ?</a>
        <hr />
        <a onClick={() => navigate('/register')} className='create-account'><UserPlus size={16} />Créer un compte</a>
      </div>
    </>
  );

  const renderMfaView = () => (
    <>
      <div className='header-setup'>
        <img src={logo} alt="Logo" className="logo" />
        <h3>Vérifier votre compte</h3>
        <p className='welcome'>
          <strong>Entrer le code</strong> envoyé à votre email ou depuis l'application.
        </p>
      </div>
      <div className="setup-step">
        <input type="text" placeholder="Code" />
      </div>
      <div className='footer-login'>
        <button className="next-button">
          Verify
        </button>
      </div>
    </>
  );

  return (
    <>
      <Seo
        title="Connexion Sécurisée | Ozzzint.fr"
        description="Accédez à votre espace membre Ozzzint. Connexion sécurisée pour utiliser nos outils OSINT et participer à notre communauté d'investigation."
        canonicalUrl="https://ozzzint.fr/login"
        robots="noindex, nofollow"
        author="Ozzzint"
      />
      <div className="setup-page">
        <div className="setup-container">
          {currentView === 'login' && renderLoginView()}
          {currentView === 'mfa' && renderMfaView()}
        </div>
      </div>
    </>
  );
}

export default Login;