import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Seo from '../components/Seo';
import '../styles/NotFound.scss';
import { Library } from 'lucide-react';
import logo from '../img/error.gif';

const NotFound = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    }

    return (
        <>
            <Seo
                title="Page Non Trouvée | Ozzzint.fr"
                description="La page que vous recherchez n'existe pas. Découvrez nos outils OSINT et ressources en cybersécurité sur la page d'accueil d'Ozzzint."
                canonicalUrl="https://ozzzint.fr/404"
                robots="noindex, nofollow"
                author="Ozzzint"
            />
            <div className="not-found">
                <div className="container">

                    <img src={logo} alt="Logo" />
                    <h1>Cette page n'existe pas/plus.</h1>
                    <p>Vous pouvez retourner à l'accueil en cliquant sur le bouton ci-dessous.</p>
                    <button onClick={handleClick}>
                        <Library size={24} color="#fff" />
                        <span>Retour à l'accueil</span>
                    </button>

                </div>
            </div>
        </>
    );

}

export default NotFound;
