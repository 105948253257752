import React, { useState } from 'react';
import { X, CreditCard, Cable, Bitcoin, Zap, Clock, Gift, Star, Shield } from 'lucide-react';
import '../styles/StoreModal.scss';


const StoreModal = ({ setShowStore }) => {
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [paymentStep, setPaymentStep] = useState('offers');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        zip: '',
        country: '',
        paymentMethod: '',
        cardNumber: '',
        expiryDate: '',
        cvv: '',
    });

    const [error, setError] = useState('');
    const [hasError, setHasError] = useState(false);

    const offers = [
        { id: 1, name: "Débutant", tokens: 50, rate: 5, price: 7.49, popular: false },
        { id: 2, name: "Expérimenté", tokens: 200, rate: 20, price: 24.99, popular: true },
        { id: 3, name: "Pro", tokens: 500, rate: 50, price: 59.99, popular: false },
        { id: 4, name: "Expert", tokens: 1000, rate: 100, price: 127.99, popular: false },
    ];


    const handleOfferSelect = (offer) => {
        setSelectedOffer(offer);
        setPaymentStep('form');
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePaymentMethodSelect = (method) => {

        // check if all fields are filled
        if (!formData.firstName || !formData.lastName || !formData.address || !formData.city || !formData.zip || !formData.country) {
            setError('Veuillez remplir tous les champs');
            setHasError(true);
            return;
        }

        // check if first name is only letters
        if (!/^[a-zA-Z]+$/.test(formData.firstName)) {
            setError('Le prénom ne doit contenir que des lettres');
            setHasError(true);
            return;
        }

        // check if last name is only letters
        if (!/^[a-zA-Z]+$/.test(formData.lastName)) {
            setError('Le nom ne doit contenir que des lettres');
            setHasError(true);
            return;
        }

        // check if address is only letters
        if (!/^[a-zA-Z0-9\s]+$/.test(formData.address)) {
            setError('L\'adresse ne doit contenir que des lettres, des chiffres et des espaces');
            setHasError(true);
            return;
        }

        // check if city is only letters
        if (!/^[a-zA-Z\s]+$/.test(formData.city)) {
            setError('La ville ne doit contenir que des lettres et des espaces');
            setHasError(true);
            return;
        }

        // check if zip is only numbers
        if (!/^\d+$/.test(formData.zip)) {
            setError('Le code postal ne doit contenir que des chiffres');
            setHasError(true);
            return;
        }

        // check if country is only letters
        if (!/^[a-zA-Z\s]+$/.test(formData.country)) {
            setError('Le pays ne doit contenir que des lettres et des espaces');
            setHasError(true);
            return;
        }




        setFormData({ ...formData, paymentMethod: method });
        if (method === 'card') {
            setPaymentStep('card');
        } else if (method === 'paypal') {
            window.open('https://www.paypal.com', '_blank');
        } else if (method === 'crypto') {
            setPaymentStep('crypto');
        }
    };

    const subscription = {
        name: "Permis de Chasseur",
        tokens: "∞",
        rate: 100,
        price: 89.99,
        originalPrice: 299.99,
    };

    const renderOffers = () => (
        <>
            <div className="token-offers">
                {offers.map((offer) => (
                    <div
                        key={offer.id}
                        className={`offer ${offer.popular ? 'popular' : ''} ${selectedOffer === offer ? 'selected' : ''}`}
                        onClick={() => handleOfferSelect(offer)}
                    >
                        {offer.popular && <span className="popular-badge">Le plus Populaire</span>}
                        <h3>{offer.name}</h3>
                        <p><Zap size={18} /> {offer.tokens} tokens</p>
                        <p><Clock size={18} /> {offer.rate} tokens/heure</p>
                        <p className="price">{offer.price}€{offer.isSubscription ? '/mois' : ''}</p>
                        <button className="buy-button">Sélectionner</button>
                    </div>
                ))}
            </div>
            <div className="subscription-offer">
                <div className="offer subscription">
                    <h3>{subscription.name}</h3>
                    <div className="offer-details">
                        <p className="tokens"><Zap size={18} /> {subscription.tokens} tokens</p>
                        <p className="rate"><Clock size={18} /> {subscription.rate}+ tokens/heure</p>
                    </div>
                    <p className="price">
                        ${subscription.price}/mois
                        <span className="original-price">${subscription.originalPrice}</span>
                    </p>
                    <button className="buy-button">Obtenir votre permis</button>
                </div>
                <div className="subscription-benefits">
                    <h4>Avantages du permis de chasseur</h4>
                    <ul>
                        <li><Shield size={18} /> Support Prioritaire</li>
                        <li><Zap size={18} /> Traitement Plus Rapide</li>
                        <li><Gift size={18} /> Fonctionnalités Exclusives</li>
                        <li><Star size={18} /> Accès à des modules Premium</li>
                        <li><Shield size={18} /> Grade "Chasseur" sur notre Discord</li>
                    </ul>
                </div>
            </div>
        </>
    );

    const renderForm = () => (
        <div className="payment-form">
            <div className="form-inputs">
                <div className="form-input">

                    {hasError && <p className="error-message">{error}</p>}

                    <div className="form-group">
                        <p>Prénom</p>
                        <input
                            type="text"
                            name="firstName"
                            placeholder="Prénom"
                            value={formData.firstName}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="form-group">
                        <p>Nom</p>
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Nom"
                            value={formData.lastName}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="form-group">
                        <p>Adresse</p>
                        <input
                            type="text"
                            name="address"
                            placeholder="Adresse"
                            value={formData.address}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="form-group">
                        <p>Ville</p>
                        <input
                            type="text"
                            name="city"
                            placeholder="Ville"
                            value={formData.city}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="form-group">
                        <p>Code postal</p>
                        <input
                            type="text"
                            name="zip"
                            placeholder="Code postal"
                            value={formData.zip}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="form-group">
                        <p>Pays</p>
                        <input
                            type="text"
                            name="country"
                            placeholder="Pays"
                            value={formData.country}
                            onChange={handleInputChange}
                        />
                    </div>


                </div>
                <div className="offer-summary">
                    <div>
                        <p>{selectedOffer.name} <strong>{selectedOffer.price}€</strong></p>
                        <p>TVA <strong>20%</strong></p>
                        <p className='total'><hr />Total : {Math.round((selectedOffer.price + (selectedOffer.price * 0.2)) * 100) / 100}€</p>
                    </div>

                    <div className="payment-methods">
                        <button onClick={() => handlePaymentMethodSelect('card')}><CreditCard /> Carte de crédit</button>
                        <button onClick={() => handlePaymentMethodSelect('paypal')}><CreditCard /> PayPal</button>
                        <button onClick={() => handlePaymentMethodSelect('crypto')}><Bitcoin /> Crypto</button>
                    </div>
                </div>

            </div>
        </div >
    );

    const renderCardForm = () => (
        <div className="card-form">
            <h3>Paiement par carte</h3>
            <input
                type="text"
                name="cardNumber"
                placeholder="Numéro de carte"
                value={formData.cardNumber}
                onChange={handleInputChange}
            />
            <input
                type="text"
                name="expiryDate"
                placeholder="Date d'expiration (MM/AA)"
                value={formData.expiryDate}
                onChange={handleInputChange}
            />
            <input
                type="text"
                name="cvv"
                placeholder="CVV"
                value={formData.cvv}
                onChange={handleInputChange}
            />
            <button className="pay-button">Payer {selectedOffer.price}€</button>
        </div>
    );

    const renderCryptoInstructions = () => (
        <div className="crypto-instructions">
            <h3>Paiement en crypto</h3>
            <ol>
                <li>Envoyez {selectedOffer.price} USDT à l'adresse suivante : 0x1234567890123456789012345678901234567890</li>
                <li>Une fois le paiement effectué, cliquez sur le bouton ci-dessous</li>
            </ol>
            <button className="check-payment-button">Vérifier le paiement</button>
        </div>
    );

    return (
        <div className="store-modal">
            <div className="modal-content">
                <button onClick={() => setShowStore(false)} className="close-button">
                    <X size={24} />
                </button>
                <h2>{selectedOffer === null ? "" : `Achat de Tokens: ${selectedOffer.name}`}</h2>
                {paymentStep === 'offers' && (
                    <>
                        <div className="offers-header">
                            <h2>Un chasseur sachant chasser...</h2>
                            <p>Choisissez le type de chasseur que vous êtes</p>
                        </div>
                        {renderOffers()}
                    </>
                )}
                {paymentStep === 'form' && renderForm()}
                {paymentStep === 'card' && renderCardForm()}
                {paymentStep === 'crypto' && renderCryptoInstructions()}
            </div>
        </div>
    );
};

export default StoreModal;