import React from 'react';
import { Shield, Globe, Lock, Server, Database, Code, AlertTriangle, CodeIcon, Book, MapPin } from 'lucide-react';
import '../styles/DomainResults.scss';

const IpResults = ({ data, ip, duration }) => {
    const renderSection = (title, content, icon) => (
        <div className="domain-section">
            <div className="domain-section-icon">
                {icon}
                <h4>{title}</h4>
            </div>
            <p>{content}.map</p>
        </div>
    );

    const renderWhois = (whois, icon) => {
        return (
            <div className="domain-section">
                <div className="domain-section-icon">
                    {icon}
                    <h4>WHOIS</h4>
                </div>
                <h4>Enregistré par :</h4>
                <p>{whois.registrar}</p>

                <h4>Date de création :</h4>
                <p>{whois.creation_date.startsWith("[datetime") ? "Date de création non disponible" : whois.creation_date}</p>
                <h4>Date d'expiration :</h4>
                <p>{whois.expiration_date.startsWith("[datetime") ? "Date d'expiration non disponible" : whois.expiration_date}</p>
            </div>
        );
    };


    const renderDNS = (title, dns, icon) => {
        return (
            <div className="domain-section">
                <div className="domain-section-icon">
                    {icon}
                    <h4>{title}</h4>
                </div>
                <h4>Records trouvés: {dns.length}</h4>

                <p>A trouvés:</p>
                <ul>
                    {dns.A.map((issue, index) => (
                        <li key={index}>{issue}</li>
                    ))}
                </ul>

                <p>MX trouvés:</p>
                <ul>
                    {dns.MX.map((issue, index) => (
                        <li key={index}>{issue}</li>
                    ))}
                </ul>

                <p>TXT trouvés:</p>
                <ul>
                    {dns.TXT.map((issue, index) => (
                        <li key={index}>{issue}</li>
                    ))}
                </ul>

            </div>
        );
    };


    const renderPorts = (title, ports, icon) => {
        return (
            <div className="domain-section">
                <div className="domain-section-icon">
                    {icon}
                    <h4>{title}</h4>
                </div>
                <h4>Ports trouvés: {ports.open_ports.length}</h4>
                <ul>
                    {ports.open_ports.map((port, index) => (
                        <li key={index}>{port}</li>
                    ))}
                </ul>
            </div>
        );
    };


    const renderGeoIP = (title, geoip, icon) => {
        return (
            <div className="domain-section">
                <div className="domain-section-icon">
                    {icon}
                    <h4>{title}</h4>
                </div>

                <div className="domain-section-info" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                    <h4>ISP:</h4>
                    <p>{geoip.isp}</p>
                </div>

                <div className="domain-section-info" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                    <h4>Pays:</h4>
                    <p>{geoip.country}</p>
                </div>

                <div className="domain-section-info" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                    <h4>Region:</h4>
                    <p>{geoip.region}</p>
                </div>

                <div className="domain-section-info" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                    <h4>Ville:</h4>
                    <p>{geoip.city}</p>
                </div>

                <div className="domain-section-info" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                    <h4>Latitude:</h4>
                    <p>{geoip.lat}</p>
                </div>

                <div className="domain-section-info" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                    <h4>Longitude:</h4>
                    <p>{geoip.lon}</p>
                </div>
            </div>
        );
    };



    return (
        <div className="domain-results">
            <h3>Résultats de {ip}</h3>
            <p>Durée de l'analyse : {duration.toFixed(2)} secondes</p>

            {renderDNS("Zone DNS", data.DNS_Checker, <Globe size={20} />)}
            {renderPorts("Ports ouverts", data.Ethical_Port_Scanner, <Server size={20} />)}
            {renderGeoIP("GeoIP", data.GeoIP_Checker, <MapPin size={20} />)}
        </div>
    );
};

export default IpResults;